import {
  Avatar,
  Dialog,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { selectUser, setLanguage } from "app/userSlice"
import { userSettingSave } from "queries/userSettingsSave"
import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import i18n from "translation/translation"

const UserSettingsPage = () => {
  const { t } = useTranslation()
  const nav = useNavigate()
  const userData = useAppSelector(selectUser)
  const dispatch = useAppDispatch()

  const handleListItemClick = async (value: string) => {
    const newUser = { ...userData.user, language: value }
    dispatch(setLanguage(newUser))
    i18n.changeLanguage(value)
    nav("/navigation")
    await userSettingSave({
      key: "default_language",
      value: value,
    })
  }

  return (
    <Dialog open={true}>
      <DialogTitle sx={{ background: "#444444", color: "white" }}>
        {t("select language")}
      </DialogTitle>
      <List sx={{ pt: 0 }}>
        <ListItem disablePadding>
          <ListItemButton onClick={() => handleListItemClick("de")}>
            <ListItemAvatar>
              <Avatar
                src={`${process.env.PUBLIC_URL}/deutsch.png`}
                sx={{ width: 50, height: 50 }}
              />
            </ListItemAvatar>
            <ListItemText primary="Deutsch" />
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem disablePadding>
          <ListItemButton onClick={() => handleListItemClick("en")}>
            <ListItemAvatar>
              <Avatar
                src={`${process.env.PUBLIC_URL}/english.png`}
                sx={{ width: 50, height: 50 }}
              />
            </ListItemAvatar>
            <ListItemText primary="English" />
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem disablePadding>
          <ListItemButton onClick={() => handleListItemClick("ro")}>
            <ListItemAvatar>
              <Avatar
                src={`${process.env.PUBLIC_URL}/romana.png`}
                sx={{ width: 50, height: 50 }}
              />
            </ListItemAvatar>
            <ListItemText primary="Română" />
          </ListItemButton>
        </ListItem>
      </List>
    </Dialog>
  )
}

export default UserSettingsPage
