import { fetchGQL } from "app/api"
import {
  ArticleSplitInput,
  ArticleSplitMutation,
  ArticleSplitMutationVariables,
} from "generated/graphql"
import gql from "graphql-tag"

const ARTICLE_SPLIT = gql`
  mutation articleSplit($in: ArticleSplitInput!) {
    articleSplit(in: $in) {
      statusCode
      message
      orderDetails {
        orderID
        status
        sh
        plantCount
        itemCount
        packageCount
        targetDate
        deliveryAddress {
          id
          firstName
          lastName
          anrede
          company
          fullName
          street
          zipCode
          city
          country
          countryCode
        }
        items {
          id
          artNum
          name
          size
          count
          supplier
          tagged
          timeTagged
          personTagged
          distributed
          timeDistributed
          personDistributed
          isSplitted
          isDeleted
          isPalletArticle
          currentPrice
          pmLocation
        }
      }
      newOrderDetails {
        orderID
        status
        sh
        plantCount
        itemCount
        packageCount
        targetDate
        deliveryAddress {
          id
          firstName
          lastName
          anrede
          company
          fullName
          street
          zipCode
          city
          country
          countryCode
        }
        items {
          id
          artNum
          name
          size
          count
          supplier
          tagged
          timeTagged
          personTagged
          distributed
          timeDistributed
          personDistributed
          isSplitted
          isDeleted
          isPalletArticle
          currentPrice
          pmLocation
        }
      }
    }
  }
`

export const articleSplit = async (
  input: ArticleSplitInput
): Promise<ArticleSplitMutation | undefined> => {
  const variables: ArticleSplitMutationVariables = { in: { ...input } }
  const data = await fetchGQL<
    ArticleSplitMutation,
    ArticleSplitMutationVariables
  >(ARTICLE_SPLIT, variables)
  return data
}
