import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt"
import EuroSymbolIcon from "@mui/icons-material/EuroSymbol"
import LooksOneOutlinedIcon from "@mui/icons-material/LooksOneOutlined"
import SaveIcon from "@mui/icons-material/Save"
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material"
import CustomInput from "component/CustomInput"
import { ArticleNewInput } from "generated/graphql"
import { t } from "i18next"
import { useSnackbar } from "notistack"
import { articleNew } from "queries/articleNew"
import { newArticleDistribute } from "queries/newArticleDistribute"
import React, { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { isChar } from "utils/isChar"
import { removeFirstChar } from "utils/removeFirstChar"
import { AppRoute } from "utils/routes"
import { Speech, speech } from "utils/speech"

const iconCss: React.CSSProperties = {
  color: "success.light",
}

type ScanRes = {
  artNum: number
  count: number
  currentPrice: number
  name: string
  size: string
  pmLocation: string
}

const TreasureTroveArticlePage = () => {
  const nav = useNavigate()
  const barcodeInputRef = useRef<HTMLInputElement>(null)
  const [barcode, setBarcode] = useState<string>("")
  const [background, setBackground] = useState<string>("")
  const [scanRes, setScanRes] = useState<ScanRes | null>(null)
  const [status, setStatus] = useState("")

  const [valueCount, setValueCount] = useState<number>(0)
  const [valuePrice, setValuePrice] = useState<number>(0)
  const [valuePosition, setValuePosition] = useState<string>("")
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (barcodeInputRef.current) {
      barcodeInputRef.current.focus()
    }
  }, [barcode])

  const getOrderDetails = async (barcode: string) => {
    if (isChar(barcode[0])) {
      barcode = removeFirstChar(barcode)
    }
    const res = await newArticleDistribute({
      barcode: barcode.toString(),
      hasSuffixStep1: true,
    })
    if (res?.orderItemDistribute.scannedArticle != null) {
      setScanRes(res.orderItemDistribute.scannedArticle)
      setValueCount(res.orderItemDistribute.scannedArticle.count)
      setValuePosition(res.orderItemDistribute.scannedArticle.pmLocation)
      setBackground("success.light")
      setStatus("OK")
      const newPrice =
        Math.round(res.orderItemDistribute.scannedArticle.currentPrice * 0.7) -
        0.01
      setValuePrice(newPrice)
      speech(Speech.Ok)
    } else {
      setBackground("error.light")
      setStatus(t("BARCODE_INVALID"))
      setScanRes(null)
      speech(Speech.Stop)
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault()
      getOrderDetails(barcode)
      setBarcode("")
    }
  }

  const handleBarcodeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBarcode(event.target.value)
  }

  const handleChangeCount = (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const newCount = parseInt(event.target.value)
      setValueCount(newCount)
    } catch {
      enqueueSnackbar(t("onlyNumbers"), { variant: "warning" })
    }
  }

  const handleChangePrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const newPrice = parseFloat(event.target.value)
      setValuePrice(newPrice)
    } catch {
      enqueueSnackbar(t("onlyNumbers"), { variant: "warning" })
    }
  }

  const handleChangePosition = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValuePosition(event.target.value)
  }

  const doArticleNew = async (input: ArticleNewInput) => {
    const res = await articleNew(input)
    if (res) {
      if (res.articleNew) {
        enqueueSnackbar(t("doesWork"), { variant: "success" })
        speech(Speech.Ok)
      } else {
        enqueueSnackbar(t("doesNotWork"), { variant: "warning" })
        speech(Speech.Stop)
      }
    }
    setScanRes(null)
    setStatus("")
    setBackground("")
    setValuePosition("")
  }

  const handleSaveClick = () => {
    if (scanRes) {
      const newArticle: ArticleNewInput = {
        sourceArticleBDB: scanRes.artNum.toString(),
        newPrice: valuePrice,
        position: valuePosition,
        quantity: valueCount,
      }
      doArticleNew(newArticle)
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        backgroundColor: background,
        flexGrow: 1,
        flex: 1,
        paddingTop: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          alignItems: "flex-start",
          paddingRight: "1%",
          paddingLeft: "1%",
        }}
      >
        <IconButton
          onClick={() => {
            nav(AppRoute.NAVIGATION)
          }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography
          sx={{
            padding: "8px",
          }}
          variant="body1"
        >
          {t("treasureTroveArticle")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <CustomInput
            sx={{ width: "8rem" }}
            id="barcode"
            placeholder="Barcode"
            data-cy="barcodeInput"
            inputRef={barcodeInputRef}
            value={barcode}
            onChange={handleBarcodeInput}
            onKeyDown={handleKeyDown}
          />
        </Box>
      </Box>

      <Box
        sx={{
          paddingTop: "1%",
          paddingRight: "1%",
          paddingLeft: "1%",
          height: `calc(100vh - 75px)`,
          width: "100%",
        }}
      >
        <Card sx={{ mb: 1 }}>
          <CardContent>
            <Typography sx={{ textAlign: "center" }} variant="h6">
              {status}
            </Typography>
          </CardContent>
        </Card>

        {scanRes && (
          <>
            <Grid container spacing={1} sx={{ mb: 1 }}>
              <Grid item xs={2}>
                <Card>
                  <Typography sx={{ textAlign: "center" }}>
                    {t("amount")}
                  </Typography>
                  <Typography sx={{ textAlign: "center" }}>
                    {scanRes.count}
                  </Typography>
                </Card>
              </Grid>

              <Grid item xs={8}>
                <Card>
                  <Typography sx={{ textAlign: "center" }}>
                    {t("botanicalName")}
                  </Typography>{" "}
                  <Typography sx={{ textAlign: "center" }}>
                    {scanRes.name}
                  </Typography>
                </Card>
              </Grid>
              <Grid item xs={2}>
                <Card>
                  <Typography sx={{ textAlign: "center" }}>
                    {t("size")}
                  </Typography>
                  <Typography sx={{ textAlign: "center" }}>
                    {scanRes.size}
                  </Typography>
                </Card>
              </Grid>
            </Grid>

            <Box>
              <Card sx={{ overflow: "auto", padding: 1, paddingBottom: 2 }}>
                <TextField
                  sx={{ mt: 2 }}
                  label="Bestand"
                  id="bestand"
                  fullWidth
                  type="number"
                  onChange={handleChangeCount}
                  value={valueCount}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LooksOneOutlinedIcon sx={iconCss} />
                      </InputAdornment>
                    ),
                  }}
                ></TextField>

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <TextField
                    sx={{ mt: 2, width: "49%" }}
                    label="neuer Preis"
                    id="neuerPreis"
                    value={valuePrice}
                    onChange={handleChangePrice}
                    type="number"
                    inputProps={{ step: 0.01, min: 0 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EuroSymbolIcon sx={iconCss} />
                        </InputAdornment>
                      ),
                    }}
                  ></TextField>
                  <TextField
                    sx={{ mt: 2, width: "49%" }}
                    label="alter Preis"
                    id="alterPreis"
                    type="number"
                    inputProps={{ step: 1.0, min: 0 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EuroSymbolIcon sx={iconCss} />
                        </InputAdornment>
                      ),
                    }}
                    value={scanRes?.currentPrice}
                    disabled
                  ></TextField>
                </Box>

                <TextField
                  sx={{ mt: 2 }}
                  label={t("position")}
                  id="position"
                  fullWidth
                  value={valuePosition}
                  onChange={handleChangePosition}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EditLocationAltIcon sx={iconCss} />
                      </InputAdornment>
                    ),
                  }}
                ></TextField>

                <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                  <Button
                    variant="contained"
                    startIcon={<SaveIcon />}
                    sx={{ mt: 2 }}
                    onClick={handleSaveClick}
                  >
                    {t("save")}
                  </Button>
                </Box>
              </Card>
            </Box>
          </>
        )}
      </Box>
    </Box>
  )
}
export default TreasureTroveArticlePage
