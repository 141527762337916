import { store } from "app/store"
import { AuthRequest } from "generated/graphql"
import { DocumentNode } from "graphql"
import { enqueueSnackbar } from "notistack"
import { logout } from "./userSlice"

// additonal fetch functions for public route login which is rest
export const fetchLogin = async (req: AuthRequest) => {
  const response = await fetch(getBaseURL() + "/user/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username: req.username,
      password: req.password,
    }),
  })
  return await response.json()
}

//fetching usernamelist for login page
export const fetchUserNameList = async (
  authkey: string
): Promise<string[] | undefined> => {
  const response = await fetch(
    getBaseURL() + `/username/list?authkey=${authkey}`
  )
  if (!response.ok) {
    return undefined
  }
  const data: any = await response.json()
  return data.UsernameList as string[]
}

const getBaseURL = (): string => {
  const state = store.getState().settingState
  let url = ""
  url += state.URI
  if (state.PORT !== "") {
    url += ":" + state.PORT
  }
  return url
}

export const fetchPrintQRCode = async (printerID: string): Promise<boolean> => {
  const response = await fetch(
    getBaseURL() + `/print/securityToken?barcode=${printerID}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
  if (response.status !== 200) {
    return false
  }
  return true
}

// NOTE: prewritten GQL fetch function for future
export const fetchGQL = async <TData = any, TVariables = Record<string, any>>(
  query: DocumentNode,
  variables?: TVariables
): Promise<TData | undefined> => {
  const token = store.getState().userState.token
  const response = await fetch(getBaseURL() + "/gql", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      query: query.loc?.source.body,
      variables,
    }),
  })

  if (response.status === 401) {
    // user will be rerouted to login automatically the store knows best
    store.dispatch(logout())
    return undefined
  }
  const res = await response.json()
  if (res.errors) {
    const msgs: string[] = res.errors.map((e: { message: string }) => e.message)
    enqueueSnackbar(msgs.join(","), { variant: "error", persist: true })
    return undefined
  }

  return res.data as TData
}
