import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
} from "@mui/material"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { selectSettings, setSettingState } from "app/settingSlice"
import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next"

interface SettingDialogProps {
  open: boolean
  onClose: () => void
}

const SettingDialog = (props: SettingDialogProps) => {
  const { open, onClose } = props

  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const refUri = useRef<HTMLInputElement>(null)
  const refPort = useRef<HTMLInputElement>(null)

  const settingState = useAppSelector(selectSettings)

  const [valUri, setValUri] = useState(settingState.URI ?? "")
  const [valPort, setValPort] = useState(settingState.PORT ?? "")
  const [checkedCarton, setCheckedCarton] = useState(
    settingState.isCartonEnabled
  )

  const handleChangeUri = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValUri(event.target.value)
  }

  const handleChangePort = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setValPort(value.replace(/[^0-9]/g, ""))
  }

  const handleSave = () => {
    if (valUri !== "" && valPort !== "") {
      dispatch(
        setSettingState({
          PORT: valPort,
          URI: valUri,
          isCartonEnabled: checkedCarton,
        })
      )
      onClose()
    }
  }

  return (
    <Dialog open={open} fullWidth maxWidth="xs" onClose={onClose}>
      <DialogTitle
        sx={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        {t("networkSettings")}
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={1} sx={{ mt: 0 }}>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  checked={checkedCarton}
                  onChange={() => setCheckedCarton((state) => !state)}
                />
              }
              label="Karton on/off"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="URI"
              id="URI"
              fullWidth
              data-cy="uriInput"
              value={valUri}
              onChange={handleChangeUri}
              inputRef={refUri}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Port"
              id="PORT"
              fullWidth
              data-cy="portInput"
              value={valPort}
              onChange={handleChangePort}
              inputRef={refPort}
              inputProps={{
                maxLength: 5,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <Box
        sx={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Button
          variant="contained"
          onClick={handleSave}
          sx={{ ml: 3, mr: 3, mb: 3 }}
        >
          {t("save")}
        </Button>
        <Button
          variant="text"
          color="secondary"
          onClick={onClose}
          sx={{ ml: 3, mr: 3, mb: 3 }}
        >
          {t("cancel")}
        </Button>
      </Box>
    </Dialog>
  )
}

export default SettingDialog
