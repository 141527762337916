import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "app/store"
import { fetchLogin } from "app/api"
import { AuthRequest, AuthResponse, User } from "generated/graphql"

export interface userState {
  token: string
  user: User
  authkey: string
  status: "idle" | "loading" | "failed"
}

const initialState: userState = {
  token: localStorage.getItem("token") ?? "",
  user: localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user")!)
    : {
        id: 0,
        username: "",
        privileges: 0,
        language: "de",
      },
  status: "idle",
  authkey: "",
}

export const userLogin = createAsyncThunk(
  "user/login",
  async (req: AuthRequest) => {
    const response = await fetchLogin(req)
    return response
  }
)

export const userSlice = createSlice({
  name: "user",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    logout: (state) => {
      state.token = ""
      state.authkey = ""
      state.user = { id: 0, username: "", privileges: 0, language: "de" }
      localStorage.removeItem("token")
      localStorage.removeItem("user")
    },
    setAuthKey: (state, action: PayloadAction<string>) => {
      state.authkey = action.payload
    },
    setLanguage: (state, action: PayloadAction<User>) => {
      state.user = { ...action.payload }
      localStorage.setItem("user", JSON.stringify({ ...action.payload }))
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.pending, (state) => {
        state.status = "loading"
      })
      .addCase(
        userLogin.fulfilled,
        (state, action: PayloadAction<AuthResponse>) => {
          state.status = "idle"
          state.user = action.payload.user
          state.token = action.payload.token
          localStorage.setItem("token", action.payload.token)
          localStorage.setItem("user", JSON.stringify(action.payload.user))
        }
      )
      .addCase(userLogin.rejected, (state) => {
        state.status = "failed"
      })
  },
})

export const { logout, setAuthKey, setLanguage } = userSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectToken = (state: RootState) => state.userState.token
export const selectUser = (state: RootState) => state.userState

// We can also write thunks by hand, which may contain both sync and async logic.
export default userSlice.reducer
