import { Card, CardContent, Grid, Typography } from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"

interface StatusHeaderBarProps {
  itemsLabeled: number
  itemsDistributed: number
  packagesDispatched: number
}

const item: React.CSSProperties = {
  textAlign: "center",
  fontSize: "0.75rem",
}

const StatusHeaderBar = (props: StatusHeaderBarProps) => {
  const { itemsLabeled, itemsDistributed, packagesDispatched } = props
  const { t } = useTranslation()
  return (
    <Card>
      <CardContent>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={4}>
            <Typography variant="body1" sx={item}>
              {t("labelled")}
            </Typography>
            <Typography variant="body1" sx={item}>
              {itemsLabeled}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1" sx={item}>
              {t("distributed")}
            </Typography>
            <Typography variant="body1" sx={item}>
              {itemsDistributed}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1" sx={item}>
              {t("dispatched")}
            </Typography>
            <Typography variant="body1" sx={item}>
              {packagesDispatched}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default StatusHeaderBar
