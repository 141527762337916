import { fetchGQL } from "app/api"
import {
  NewArticleDistributeMutation,
  NewArticleDistributeMutationVariables,
  OrderItemDistributeInput,
} from "generated/graphql"

import gql from "graphql-tag"

const NEW_ARTICLE_DISTRIBUTE = gql`
  mutation newArticleDistribute($input: OrderItemDistributeInput!) {
    orderItemDistribute(in: $input) {
      scannedArticle {
        artNum
        name
        count
        currentPrice
        size
        pmLocation
      }
    }
  }
`

export const newArticleDistribute = async (
  input: OrderItemDistributeInput
): Promise<NewArticleDistributeMutation | undefined> => {
  const variables: NewArticleDistributeMutationVariables = { input: input }
  const data = await fetchGQL<
    NewArticleDistributeMutation,
    NewArticleDistributeMutationVariables
  >(NEW_ARTICLE_DISTRIBUTE, variables)
  return data
}
