import CallSplitIcon from "@mui/icons-material/CallSplit"
import CreateIcon from "@mui/icons-material/Create"
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt"
import EditNoteIcon from "@mui/icons-material/EditNote"
import HistoryIcon from "@mui/icons-material/History"
import LocalOfferIcon from "@mui/icons-material/LocalOffer"
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts"
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox"
import RoomIcon from "@mui/icons-material/Room"
import PrintIcon from "@mui/icons-material/Print"

import { Box, Grid } from "@mui/material"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { fetchlibraList } from "app/libraListSlice"
import { fetchPrinters } from "app/printerSlice"
import { RootState } from "app/store"
import { selectUser } from "app/userSlice"
import NavigationHeader from "component/NavigationHeader"
import NavigationItem from "component/NavigationItem"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { AppRoute } from "utils/routes"

const iconCss: React.CSSProperties = {
  color: "white",
}

const NavigationPage = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const printerState = useAppSelector((state: RootState) => state.printerState)
  const libraState = useAppSelector((state: RootState) => state.libraState)
  const userState = useAppSelector(selectUser)

  useEffect(() => {
    if (userState.token === "") {
      navigate(AppRoute.LOGIN)
    }
    if (
      printerState.printers.length === 0 &&
      printerState.status !== "loading"
    ) {
      dispatch(fetchPrinters())
    }

    if (libraState.libras.length === 0 && libraState.status !== "loading") {
      dispatch(fetchlibraList())
    }
  }, [dispatch, libraState, printerState, userState])

  const navigationItems = [
    {
      label: t("label"),
      icon: <LocalOfferIcon sx={iconCss} data-cy="LabelButton" />,
      url: AppRoute.LABEL,
      backgroundColor: "#7b65db",
    },
    {
      label: t("distribute"),
      icon: <CallSplitIcon sx={iconCss} data-cy="DistributeButton" />,
      url: AppRoute.DIST,
      backgroundColor: "#398541",
    },
    {
      label: t("treasureTroveArticle"),
      icon: <CreateIcon sx={iconCss} data-cy="TreasureButton" />,
      url: AppRoute.TREASURE,
      backgroundColor: "#2129a3",
    },
    {
      label: t("status"),
      icon: <EditNoteIcon sx={iconCss} data-cy="StatusButton" />,
      url: AppRoute.STATUS,
      backgroundColor: "#757582",
    },
    {
      label: t("ship"),
      icon: <MoveToInboxIcon sx={iconCss} data-cy="ShipButton" />,
      url: AppRoute.SHIP,
      backgroundColor: "#423021",
      dialog: true,
    },
    {
      label: t("plantPosition"),
      icon: <EditLocationAltIcon sx={iconCss} data-cy="PlantButton" />,
      url: AppRoute.PLANT,
      backgroundColor: "#f7bf45",
    },
    {
      label: t("scanHistory"),
      icon: <HistoryIcon sx={iconCss} data-cy="HistoryButton" />,
      url: AppRoute.SCAN,
      backgroundColor: "#44a6e3",
    },
    {
      label: t("userSettings"),
      icon: <ManageAccountsIcon sx={iconCss} data-cy="SettingsButton" />,
      url: AppRoute.USER,
      backgroundColor: "#141414",
    },
    {
      label: t("warehouse"),
      icon: <RoomIcon sx={iconCss} data-cy="ShipButton" />,
      url: AppRoute.WAREHOUSE,
      backgroundColor: "#000000",
      dialog: true,
    },
    {
      label: t("libraPrinterSettings"),
      icon: <PrintIcon sx={iconCss} data-cy="PrinterButton" />,
      url: AppRoute.PRINTER_LIBRA_SETTINGS,
      backgroundColor: "#000000",
      dialog: true,
    },
  ]

  return (
    <Box>
      <NavigationHeader />
      <Grid
        container
        spacing={1}
        sx={{ mt: 1 }}
        justifyContent="center"
        alignItems="center"
      >
        {navigationItems.map((item, index) => (
          <Grid item xs={4} key={index}>
            <NavigationItem
              label={item.label}
              icon={item.icon}
              url={item.url}
              backgroundColor={item.backgroundColor}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default NavigationPage
