import { Box, IconButton, Typography } from "@mui/material"
import React from "react"
import { Link, useNavigate } from "react-router-dom"

type NavigationItemProps = {
  label: string
  icon: JSX.Element
  url: string
  backgroundColor: string
}

const NavigationItem = (props: NavigationItemProps) => {
  const { label, icon, url, backgroundColor } = props
  const nav = useNavigate()

  const handleNavigation = (url: string) => {
    nav(url)
  }
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Link to={url}>
        <IconButton
          sx={{ backgroundColor, width: 48, height: 48 }}
          onClick={() => handleNavigation(url)}
        >
          {icon}
        </IconButton>
      </Link>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height={36}
        width={130}
        overflow="hidden"
      >
        <Typography
          variant="caption"
          sx={{
            overflowWrap: "break-word",
            maxWidth: "100%",
            textAlign: "center",
            lineHeight: "18px",
          }}
        >
          {label}
        </Typography>
      </Box>
    </Box>
  )
}

export default NavigationItem
