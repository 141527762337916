import PrintIcon from "@mui/icons-material/Print"
import SettingsIcon from "@mui/icons-material/Settings"
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material"
import { fetchPrintQRCode, fetchUserNameList } from "app/api"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { selectUser, userLogin } from "app/userSlice"
import CustomDialog from "component/CustomDialog"
import SecurityScanDialog from "component/SecurityScanDialog"
import { useSnackbar } from "notistack"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { AppRoute } from "utils/routes"
import SettingDialog from "./SettingDialog"

const LoginPage = () => {
  const { t } = useTranslation()
  const iconUrl = process.env.PUBLIC_URL + "/icon.png"
  const [showPrinterDialog, setShowPrinterDialog] = useState(false)
  const [printerID, setPrinterID] = useState("")
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [userList, setUserList] = useState<string[]>([])
  const [showSecurityCodeDialog, setSecurityCodeDialog] = useState(true)
  const [settingOpen, setSettingOpen] = useState(false)
  const [autocompleteOpen, setAutocompleteOpen] = useState(false)
  const autocompleteInputRef = useRef<HTMLInputElement>(null)
  const dispatch = useAppDispatch()
  const userState = useAppSelector(selectUser)
  const { enqueueSnackbar } = useSnackbar()

  const handlePrinterIDChange = (s: string) => {
    setPrinterID(s)
  }

  const handlePrinterIDSubmit = () => {
    let pid = printerID.trim()
    if (pid.length === 0) {
      enqueueSnackbar(t("printerIDEmpty"), { variant: "error" })
      return
    }
    if (pid[0].toLowerCase() === "p") {
      pid = pid.substring(1)
    }
    fetchPrintQRCode(pid)
      .then((res) => {
        if (res) {
          enqueueSnackbar(t("printSuccess"), { variant: "success" })
        } else {
          enqueueSnackbar(t("printFailed"), { variant: "error" })
        }
        setShowPrinterDialog(false)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setPrinterID("")
      })
  }

  const getTimeGreeting = () => {
    const currentHour = new Date().getHours()
    if (currentHour < 12 && currentHour > 4) {
      return t("goodMorning")
    } else if (currentHour < 18) {
      return t("goodAfternoon")
    } else if (currentHour < 22) {
      return t("goodEvening")
    } else {
      return t("goodNight")
    }
  }

  const navigate = useNavigate()

  const handleLogin = () => {
    dispatch(userLogin({ username, password }))
  }
  useEffect(() => {
    if (userState.status === "failed") {
      enqueueSnackbar(t("loginFailed"), { variant: "error" })
    }
    if (userState.status === "idle" && userState.token.length > 0) {
      enqueueSnackbar(t("loginSuccess"), { variant: "success" })
      navigate(AppRoute.NAVIGATION)
    }
  }, [userState, enqueueSnackbar, navigate, t])

  const handleLoginCommit = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      handleLogin()
    }
  }

  const handleAuthkeyConfirm = (authkey: string) => {
    // try to fetch username list
    fetchUserNameList(authkey.trim())
      .then((res) => {
        if (res) {
          enqueueSnackbar(t("authkeyCorrect"), { variant: "success" })
          setUserList(res)
          setSecurityCodeDialog(false)
          autocompleteInputRef?.current?.focus()
        } else {
          enqueueSnackbar(t("authkeyIncorrect"), { variant: "error" })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      height="100vh"
      width="80%"
      minWidth="300px"
      marginX="auto"
      padding={2}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        alignSelf="flex-start"
        paddingTop="2rem"
      >
        <Box component={"img"} src={iconUrl} sx={{ width: "100px" }} />
        <Typography variant="h4" sx={{ marginTop: "1rem" }}>
          {getTimeGreeting()}
        </Typography>
        <Typography variant="h6">{t("loginPlease")}</Typography>
      </Box>

      <Box flexGrow={1}></Box>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        pb="2rem"
        width="100%"
      >
        <Autocomplete
          open={autocompleteOpen}
          onOpen={() => {
            if (userList && userList.length) {
              setAutocompleteOpen(true)
            }
          }}
          onClose={() => setAutocompleteOpen(false)}
          options={userList}
          onInputChange={(_, newValue) => {
            setUsername(newValue)
          }}
          renderInput={(params) => (
            <TextField
              inputRef={autocompleteInputRef}
              {...params}
              label={t("username")}
              variant="outlined"
              fullWidth
              data-cy="LoginPageUsername"
            />
          )}
          sx={{ marginBottom: "20px", width: "100%", minWidth: "300px" }}
        />
        <TextField
          id="password"
          variant="outlined"
          type="password"
          placeholder={t("password")}
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={handleLoginCommit}
          sx={{ marginBottom: "20px", width: "100%", minWidth: "300px" }}
          data-cy="LoginPagePassword"
        />
        <Button
          data-cy="LoginPageLoginButton"
          variant="contained"
          color="primary"
          onClick={handleLogin}
          disabled={
            userState.status === "loading" || username === "" || password === ""
          }
          sx={{ marginBottom: "20px", width: "100%", minWidth: "200px" }}
        >
          {userState.status === "loading" ? (
            <CircularProgress size={24} />
          ) : (
            t("login")
          )}
        </Button>
      </Box>
      <Button
        variant="outlined"
        color="primary"
        startIcon={<SettingsIcon />}
        sx={{ width: "35%", minWidth: "200px" }}
        onClick={() => {
          setSettingOpen(true)
        }}
      >
        {t("settings")}
      </Button>
      <SecurityScanDialog
        open={showSecurityCodeDialog}
        onAuthkeyConfirm={handleAuthkeyConfirm}
        openPrintDialog={() => setShowPrinterDialog(true)}
        openSettingDialog={() => setSettingOpen(true)}
        isSettingOpen={settingOpen}
      />
      <SettingDialog open={settingOpen} onClose={() => setSettingOpen(false)} />
      <CustomDialog
        open={showPrinterDialog}
        setOpen={setShowPrinterDialog}
        val={printerID}
        onChange={handlePrinterIDChange}
        onSubmit={handlePrinterIDSubmit}
        headerText={t("scanPrinter")}
        icon={<PrintIcon />}
      />
    </Box>
  )
}

export default LoginPage
