import { InputBase, styled } from "@mui/material"

const CustomInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 0,
    position: "relative",
    border: "1px solid black",
    color: theme.palette.text.primary,
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.common.white
        : theme.palette.common.black,
    padding: "10px 12px",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        borderRadius: 0,
        borderWidth: 1,
      },
      "&:hover fieldset": {
        borderColor: "black",
        borderRadius: 0,
        borderWidth: 1,
      },
      "&.Mui-focused fieldset": {
        borderColor: "black",
        borderRadius: 0,
        borderWidth: 1,
      },
    },
  },
}))

export default CustomInput
