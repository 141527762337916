import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit"
import libraReducer from "app/libraListSlice"
import printerReducer from "app/printerSlice"
import settingReducer from "app/settingSlice"
import userReducer from "app/userSlice"

export const store = configureStore({
  reducer: {
    userState: userReducer,
    settingState: settingReducer,
    printerState: printerReducer,
    libraState: libraReducer,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
