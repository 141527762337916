import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material"
import CustomInput from "component/CustomInput"
import CustomTabPanel from "component/CustomTabPanel"
import { Status, ScanResponse } from "generated/graphql"
import { tagItem } from "queries/tagItem"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { AppRoute } from "utils/routes"
import LabellingInfo from "./LabellingInfo"
import LabellingOptions from "./LabellingOptions"
import LabellingScanHistory from "./LabellingScanHistory"
import LabellingStatus from "./LabellingStatus"
import { removeFirstChar } from "utils/removeFirstChar"
import { isChar } from "utils/isChar"
import { Speech, speech } from "utils/speech"

const LabellingPage = (): JSX.Element => {
  const nav = useNavigate()
  const [barcode, setBarcode] = useState<string>("")
  const [barcode2, setBarcode2] = useState<string>("")
  const [value, setValue] = useState(0)
  const [tagRes, setTagRes] = useState<ScanResponse | null>(null)
  const [background, setBackground] = useState("")
  const barcodeInputRef = useRef<HTMLInputElement>(null)
  const { t } = useTranslation()
  const theme = useTheme()

  useEffect(() => {
    if (barcodeInputRef.current) {
      barcodeInputRef.current.focus()
    }
  }, [barcode])

  const setOptions = (newPmLocation: string, newIsPalletArticle: boolean) => {
    if (
      tagRes != null &&
      tagRes.orderDetails != null &&
      tagRes.scannedArticle != null
    ) {
      const newScannedArticle = {
        ...tagRes.scannedArticle,
        isPalletArticle: newIsPalletArticle,
        pmLocation: newPmLocation,
      }

      const newItems = tagRes.orderDetails.items.map((value: any) => {
        if (tagRes.scannedArticle != null) {
          if (value.id === tagRes.scannedArticle.id) {
            return newScannedArticle
          }
        }
        return value
      })

      setTagRes((value: any) => ({
        ...value,
        scannedArticle: newScannedArticle,
        orderDetails: { ...value.orderDetails, items: newItems },
      }))
    }
  }

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const handleBarcodeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBarcode(event.target.value)
  }

  const getOrderDetails = async (barcode: string) => {
    if (isChar(barcode[0])) {
      barcode = removeFirstChar(barcode)
    }
    const res = await tagItem(barcode)
    if (res?.tagItem) {
      setTagRes(res.tagItem)
      switch (res.tagItem.status) {
        case Status.BarcodeInvalid:
        case Status.Stop:
        case Status.AlreadyTagged:
          setBackground(theme.palette.error.light)
          speech(Speech.Stop)
          break
        case Status.OrderComplete:
          speech(Speech.BoxDone)
          setBackground(theme.palette.success.light)
          break
        case Status.Success:
          speech(Speech.Ok)
          setBackground(theme.palette.success.light)
          break
        default:
          setBackground(theme.palette.background.default)
      }
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault()
      getOrderDetails(barcode)
      setBarcode2(barcode)
      setBarcode("")
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        backgroundColor: background,
        flexGrow: 1,
        flex: 1,
        paddingTop: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          alignItems: "flex-start",
          paddingRight: "1%",
          paddingLeft: "1%",
        }}
      >
        <IconButton
          onClick={() => {
            nav(AppRoute.NAVIGATION)
          }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography
          sx={{
            padding: "8px",
          }}
          variant="body1"
        >
          {t("labelling")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <CustomInput
            sx={{ width: "8rem" }}
            id="barcode"
            placeholder="Barcode"
            data-cy="barcodeInput"
            inputRef={barcodeInputRef}
            value={barcode}
            onChange={handleBarcodeInput}
            onKeyDown={handleKeyDown}
          />
        </Box>
      </Box>

      {tagRes && (
        <Box>
          <Box sx={{ paddingRight: 1, paddingLeft: 1, paddingTop: 1 }}>
            <Card>
              <CardContent>
                <Typography sx={{ textAlign: "center" }} variant="h6">
                  {t(tagRes.status)}
                </Typography>
              </CardContent>
            </Card>
            <Box
              sx={{
                paddingTop: 1,
                display: "flex",
                flex: 10,
                flexGrow: 1,
              }}
            >
              <CustomTabPanel value={value} index={0}>
                <LabellingInfo
                  orderDetails={tagRes.orderDetails}
                  scannedArticle={tagRes.scannedArticle}
                  items={
                    tagRes.orderDetails?.items?.filter((v) => {
                      return (
                        !v.tagged &&
                        !v.isSplitted &&
                        v.id !== tagRes.scannedArticle?.id
                      )
                    }) || []
                  }
                />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                {tagRes.orderDetails && (
                  <LabellingStatus
                    orderDetails={tagRes.orderDetails}
                    setTagRes={setTagRes}
                    height={392}
                  />
                )}
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                {tagRes.scannedArticle && (
                  <LabellingOptions
                    scannedArticle={tagRes.scannedArticle}
                    barcode={barcode2}
                    setOptions={setOptions}
                  />
                )}
              </CustomTabPanel>
              <CustomTabPanel value={value} index={3}>
                <LabellingScanHistory />
              </CustomTabPanel>
            </Box>
          </Box>

          <Tabs
            value={value}
            variant="fullWidth"
            centered
            sx={{
              backgroundColor: "white",
              borderBottom: 0,
            }}
            onChange={handleChange}
          >
            <Tab label={t("info")} data-cy="tabInfo" id="0" />
            <Tab label={t("status")} data-cy="tabStatus" id="1" />
            <Tab label={t("options")} data-cy="tabOptions" id="2" />
            <Tab label={t("scanHistory")} data-cy="tabHistory" id="3" />
          </Tabs>
        </Box>
      )}
    </Box>
  )
}

export default LabellingPage
