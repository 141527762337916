import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import en from './en.json'
import de from './de.json'
import ro from './ro.json'

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translations: en },
      de: { translations: de },
      ro: { translations: ro }
    },
    lng: "de", // Default language
    fallbackLng: "de",
    debug: true,

    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false,

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
