import { Box } from "@mui/material"
import ArticleInfo from "component/ArticleInfo"
import ArticleList from "component/ArticleList"
import OrderInfo from "component/OrderInfo"
import { Article, Order } from "generated/graphql"
import React from "react"

export interface LabellingInfoProps {
  orderDetails?: Order | null
  scannedArticle?: Article | null
  items: Article[]
}

const LabellingInfo = (props: LabellingInfoProps) => {
  const { orderDetails, scannedArticle, items } = props

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Box sx={{ flex: 1, pb: 1 }}>
        {orderDetails && <OrderInfo orderDetails={orderDetails} />}
      </Box>

      <Box sx={{ flex: 1, pb: 1 }}>
        {scannedArticle && <ArticleInfo article={scannedArticle} />}
      </Box>
      <Box sx={{ flex: 1, pb: 1 }}>
        <ArticleList articles={items} />
      </Box>
    </Box>
  )
}

export default LabellingInfo
