import { fetchGQL } from "app/api"
import {
  UserAchievementQuery,
  UserAchievementQueryVariables,
} from "generated/graphql"

import gql from "graphql-tag"

const USER_ACHIEVMENTS = gql`
  query userAchievement($userID: Int!) {
    userAchievment(userID: $userID) {
      itemsDistributed
      itemsLabled
      packagesDispatched
      articleHistory {
        taskType
        orderID
        round
        sh
        quantity
        articleName
        from
        to
        finishedAt
      }
    }
  }
`

export const userAchievement = async (
  userID: number
): Promise<UserAchievementQuery | undefined> => {
  const variables: UserAchievementQueryVariables = { userID }
  const data = await fetchGQL<
    UserAchievementQuery,
    UserAchievementQueryVariables
  >(USER_ACHIEVMENTS, variables)
  return data
}
