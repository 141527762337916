import CancelIcon from "@mui/icons-material/Cancel"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { Box, Typography } from "@mui/material"
import { Article } from "generated/graphql"
import { t } from "i18next"
import React from "react"

interface CollapsibleRowArticlesProps {
  article: Article
}

const CollapsibleRowArticle = (props: CollapsibleRowArticlesProps) => {
  const { article } = props
  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="subtitle2"
            color={
              article.tagged && article.distributed
                ? "success.main"
                : "error.main"
            }
          >
            {article.name}
          </Typography>

          <Typography variant="body2">{article.size}</Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="body2">
            {article.count} {t("piece")}
          </Typography>
          <Box sx={{ display: "flex" }}>
            {article.tagged ? (
              <CheckCircleIcon color="success" fontSize="small" />
            ) : (
              <CancelIcon color="error" fontSize="small" />
            )}
            <Typography variant="body2">{t("labelled")}</Typography>
            {article.distributed ? (
              <CheckCircleIcon color="success" fontSize="small" />
            ) : (
              <CancelIcon color="error" fontSize="small" />
            )}
            <Typography variant="body2">{t("distributed")}</Typography>
          </Box>
        </Box>

        <Box>
          {article.isPalletArticle && (
            <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
              <Typography variant="subtitle2" color={"info.main"}>
                {t("sendAsPalette")}
              </Typography>
            </Box>
          )}
          {article.pmLocation && (
            <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
              <Typography variant="subtitle2" color={"info.main"}>
                {t("liesBy")} {article.pmLocation}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default CollapsibleRowArticle
