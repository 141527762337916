import { Dialog, DialogTitle, DialogContent } from "@mui/material"
import { t } from "i18next"
import React from "react"
import { useNavigate } from "react-router-dom"
import ScanHistory from "component/ScanHistory"

const ScanHistoryPage = () => {
  const nav = useNavigate()

  const handleClose = () => {
    nav("/navigation")
  }

  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogTitle>{t("Scan History")}</DialogTitle>
      <DialogContent>
        <ScanHistory></ScanHistory>
      </DialogContent>
    </Dialog>
  )
}

export default ScanHistoryPage
