import { fetchGQL } from "app/api"
import {
  SpecialChangesInput,
  SpecialChangesMutation,
  SpecialChangesMutationVariables,
} from "generated/graphql"
import gql from "graphql-tag"

const SPECIAL_CHANGES = gql`
  mutation specialChanges($in: SpecialChangesInput!) {
    specialChanges(in: $in)
  }
`

export const specialChanges = async (
  input: SpecialChangesInput
): Promise<SpecialChangesMutation | undefined> => {
  const variables: SpecialChangesMutationVariables = { in: { ...input } }
  const data = await fetchGQL<
    SpecialChangesMutation,
    SpecialChangesMutationVariables
  >(SPECIAL_CHANGES, variables)
  return data
}
