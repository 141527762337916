import CloseIcon from "@mui/icons-material/Close"
import LabellingPage from "pages/LabellingPage"
import React from "react"
import ReactDOM from "react-dom/client"
//import reportWebVitals from './reportWebVitals';
//Importing fonts no googlefonts cdn as it is our requirement for all projects to use NO CDN for tracking reasons
import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"
import {
  createTheme,
  CssBaseline,
  IconButton,
  ThemeProvider,
} from "@mui/material"
import { store } from "app/store"
import { closeSnackbar, SnackbarProvider } from "notistack"
import DistributePage from "pages/DistributePage"
import LibraPrinterSettings from "pages/LibraPrinterSettings"
import LoginPage from "pages/LoginPage"
import NavigationPage from "pages/NavigationPage"
import ScanHistoryPage from "pages/ScanHistoryPage"
import ShipmentPage from "pages/ShipmentPage"
import StatusPage from "pages/StatusPage"
import TreasureTroveArticlePage from "pages/TreasureTroveArticlePage"
import UserSettingsPage from "pages/UserSettingsPage"
import { I18nextProvider } from "react-i18next"
import { Provider } from "react-redux"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import i18n from "translation/translation"
import { AppRoute } from "utils/routes"
import LagerPage from "pages/LagerPage"
import PlantPositionPage from "pages/PlantPositionPage"

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#7fb962",
      contrastText: "#fff",
    },
    secondary: {
      main: "#9c62b9",
      contrastText: "#fff",
    },
    grey: {
      "100": "#808080",
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          textAlign: "center",
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 0,
          "&:last-child": {
            paddingBottom: 0,
          },
        },
      },
    },
  },
})

const router = createBrowserRouter([
  {
    path: AppRoute.LOGIN,
    element: <LoginPage />,
  },
  {
    path: AppRoute.NAVIGATION,
    element: <NavigationPage />,
  },
  {
    path: AppRoute.LABEL,
    element: <LabellingPage />,
  },
  {
    path: AppRoute.SHIP,
    element: <ShipmentPage />,
  },
  {
    path: AppRoute.DIST,
    element: <DistributePage />,
  },
  {
    path: AppRoute.USER,
    element: <UserSettingsPage />,
  },
  {
    path: AppRoute.STATUS,
    element: <StatusPage />,
  },
  {
    path: AppRoute.SCAN,
    element: <ScanHistoryPage />,
  },
  {
    path: AppRoute.PLANT,
    element: <PlantPositionPage />,
  },
  {
    path: AppRoute.TREASURE,
    element: <TreasureTroveArticlePage />,
  },
  {
    path: AppRoute.PRINTER_LIBRA_SETTINGS,
    element: <LibraPrinterSettings />,
  },
  {
    path: AppRoute.WAREHOUSE,
    element: <LagerPage />,
  },
])

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <I18nextProvider i18n={i18n}>
          <CssBaseline />
          <SnackbarProvider
            maxSnack={3}
            preventDuplicate={true}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            action={(snackbarId) => (
              <IconButton
                onClick={() => closeSnackbar(snackbarId)}
                size="small"
              >
                <CloseIcon />
              </IconButton>
            )}
          >
            <RouterProvider router={router} />
          </SnackbarProvider>
        </I18nextProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// NOTE: This is disabled for now as it is not needed for this project but you can enable it if you want to
//reportWebVitals(console.log);
