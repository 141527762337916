import {
  Box,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material"
import { useAppSelector } from "app/hooks"
import { selectUser } from "app/userSlice"
import { Article } from "generated/graphql"
import { enqueueSnackbar } from "notistack"
import { specialChanges } from "queries/specialChanges"
import React, { ChangeEvent, useState } from "react"
import { useTranslation } from "react-i18next"

interface LabellingOptionsProps {
  scannedArticle: Article
  barcode: string
  setOptions: (arg1: string, arg2: boolean) => void
}

const LabellingOptions = (props: LabellingOptionsProps) => {
  const { scannedArticle, barcode, setOptions } = props
  const userData = useAppSelector(selectUser)
  const { t } = useTranslation()
  const [liesAt, setLiesAt] = useState(
    scannedArticle.pmLocation == "" ? false : true
  )
  const [deactivate, setDeaktivate] = useState(false)
  const [isPalletArticle, setIsPalletArticle] = useState(false)
  const [pmLocation, setPmLocation] = useState(scannedArticle.pmLocation)

  var height = 170

  const handleSubmit = async () => {
    let sendAbleBarcode = barcode
    if (sendAbleBarcode.length <= 0) {
      return
    }
    if (sendAbleBarcode[0].toLowerCase() === "a") {
      sendAbleBarcode = sendAbleBarcode.substring(1)
    }
    const input = {
      barcode: sendAbleBarcode,
      isPalletArticle: isPalletArticle,
      pmLocation: pmLocation,
    }
    const res = await specialChanges(input)
    if (res?.specialChanges) {
      enqueueSnackbar(t("success"), { variant: "success" })
      setOptions(pmLocation, isPalletArticle)
    }
  }

  const handleRadioClick = (
    _: ChangeEvent<HTMLInputElement>,
    value: string
  ): void => {
    switch (value) {
      case "liesAt":
        setLiesAt(true)
        setIsPalletArticle(false)
        setDeaktivate(false)
        setPmLocation(userData.user.username)
        break
      case "isPalette":
        setLiesAt(false)
        setIsPalletArticle(true)
        setDeaktivate(false)
        setPmLocation("")
        break
      case "deactivate":
        setLiesAt(false)
        setIsPalletArticle(false)
        setDeaktivate(true)
        setPmLocation("")
        break
    }
  }

  return (
    <Box sx={{ paddingBottom: 1 }}>
      <Card
        sx={{
          marginTop: 1,
          height: `calc(100vh - ${height}px)`,
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <FormControl>
              <RadioGroup onChange={handleRadioClick}>
                <FormControlLabel
                  value="liesAt"
                  checked={liesAt}
                  control={<Radio />}
                  label={t("isWith")}
                />
                <FormControlLabel
                  value="isPalette"
                  checked={isPalletArticle}
                  control={<Radio />}
                  label={t("sendAsPalette")}
                />
                <FormControlLabel
                  value="deactivate"
                  checked={deactivate}
                  control={<Radio />}
                  label={t("deactivateAll")}
                />
              </RadioGroup>
              <Button
                data-cy="buttonSave"
                variant="contained"
                sx={{ marginTop: 5 }}
                onClick={handleSubmit}
              >
                {t("save")}
              </Button>
            </FormControl>
          </Box>
        </Box>
      </Card>
    </Box>
  )
}

export default LabellingOptions
