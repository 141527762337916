import {
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
} from "@mui/material"
import { useAppSelector } from "app/hooks"
import { selectUser } from "app/userSlice"
import { ArticleHistory, UserHistory } from "generated/graphql"
import { t } from "i18next"
import { userAchievement } from "queries/userArchievement"
import React from "react"
import { useState, useEffect } from "react"

const ScanHistory = () => {
  const [userHistory, setUserHistory] = useState<UserHistory | null>(null)
  const user = useAppSelector(selectUser)

  const getUserHistory = async () => {
    const res = await userAchievement(user.user.id)
    if (res) {
      setUserHistory(res.userAchievment)
    }
  }

  useEffect(() => {
    getUserHistory()
  }, [])

  return (
    <>
      {userHistory?.articleHistory.map(
        (history: ArticleHistory, index: number) => (
          <Box
            sx={{ mb: 3 }}
            key={history.finishedAt + history.articleName + index}
          >
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Typography variant="body1">
                <strong>{history.quantity} </strong>x{" "}
                <strong>{history.articleName}</strong>
              </Typography>
            </Box>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {history.taskType == 1
                      ? t("labelledAt")
                      : t("distributedAt")}
                  </TableCell>
                  <TableCell>{t("SH")}</TableCell>
                  <TableCell>{t("round")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{history.finishedAt}</TableCell>
                  <TableCell>{history.sh}</TableCell>
                  <TableCell>{history.round}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        )
      )}
    </>
  )
}

export default ScanHistory
