import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "./store"

export interface settingState {
  isCartonEnabled: boolean
  URI: string // example.com , 192.168.178.23 , ...
  PORT: string // 80 ,443, ...
  selectedPrinterID: string
  selectedLibraID: string
}

const initialState: settingState = {
  isCartonEnabled: localStorage.getItem("isCartonEnabled") === "true",
  URI: localStorage.getItem("URI") ?? "http://shipping.intranet.pflanzmich.de", // use default if not set
  PORT: localStorage.getItem("PORT") ?? "1010",
  selectedPrinterID: localStorage.getItem("selectedPrinterID") ?? "",
  selectedLibraID: localStorage.getItem("selectedLibraID") ?? "",
}

export const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    setSettingState: (state, action) => {
      state.isCartonEnabled = action.payload.isCartonEnabled
      state.PORT = action.payload.PORT
      state.URI = action.payload.URI
      state.selectedLibraID = action.payload.selectedLibraID
      state.selectedPrinterID = action.payload.selectedPrinterID
      localStorage.setItem(
        "selectedPrinterID",
        action.payload.selectedPrinterID
      )
      localStorage.setItem("selectedLibraID", action.payload.selectedLibraID)
      localStorage.setItem("isCartonEnabled", action.payload.isCartonEnabled)
      localStorage.setItem("PORT", action.payload.PORT)
      localStorage.setItem("URI", action.payload.URI)
    },
    setPrinterID: (state, action) => {
      state.selectedPrinterID = action.payload
      localStorage.setItem("selectedPrinterID", action.payload)
    },
    setLibraID: (state, action) => {
      state.selectedLibraID = action.payload
      localStorage.setItem("selectedLibraID", action.payload)
    },
  },
})

export const { setSettingState, setPrinterID, setLibraID } =
  settingSlice.actions
export const selectSettings = (state: RootState) => state.settingState
export const selectPrinterID = (state: RootState) =>
  state.settingState.selectedPrinterID
export const selectLibraID = (state: RootState) =>
  state.settingState.selectedLibraID
export default settingSlice.reducer
