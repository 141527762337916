import gql from "graphql-tag"
import { fetchGQL } from "app/api"
import {
  PrintDeliveryNoteQuery,
  PrintDeliveryNoteQueryVariables,
} from "generated/graphql"

const PRINT_DELIVERY_NOTE = gql`
  query printDeliveryNote($in: PrintDeliveryNoteInput!) {
    printDeliveryNote(in: $in)
  }
`

export const printDeliveryNote = async (
  variables: PrintDeliveryNoteQueryVariables
): Promise<PrintDeliveryNoteQuery | undefined> => {
  const data = await fetchGQL<
    PrintDeliveryNoteQuery,
    PrintDeliveryNoteQueryVariables
  >(PRINT_DELIVERY_NOTE, variables)

  return data
}
