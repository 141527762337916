import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Printer, PrintersQuery } from "generated/graphql"
import { getPrinters } from "queries/printers"
import { RootState } from "./store"

export interface PrinterState {
  printers: Printer[]
  status: "idle" | "loading" | "failed"
}

const initialState: PrinterState = {
  printers: [],
  status: "idle",
}

export const fetchPrinters = createAsyncThunk(
  "printers/fetchPrinters",
  async () => {
    const response = await getPrinters()
    return response
  }
)

export const printerSlice = createSlice({
  name: "printer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPrinters.pending, (state) => {
        state.status = "loading"
      })
      .addCase(
        fetchPrinters.fulfilled,
        (state, action: PayloadAction<PrintersQuery | undefined>) => {
          state.status = "idle"
          if (action.payload?.printers !== undefined) {
            state.printers = action.payload.printers
          } else if (action.payload?.printers === undefined) {
            state.printers = []
            state.status = "failed"
          }
        }
      )
      .addCase(fetchPrinters.rejected, (state) => {
        state.status = "failed"
      })
  },
})

export const selectPrinters = (state: RootState) => state.printerState.printers

export default printerSlice.reducer
