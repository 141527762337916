import { fetchGQL } from "app/api"
import {
  UserSettingSaveInput,
  UserSettingSaveMutation,
  UserSettingSaveMutationVariables,
} from "generated/graphql"
import gql from "graphql-tag"

const USER_SETTINGS_SAVE = gql`
  mutation userSettingSave($in: UserSettingSaveInput!) {
    userSettingSave(in: $in) {
      statusCode
      message
    }
  }
`

export const userSettingSave = async (
  input: UserSettingSaveInput
): Promise<UserSettingSaveMutation | undefined> => {
  const variables: UserSettingSaveMutationVariables = { in: { ...input } }
  const data = await fetchGQL<
    UserSettingSaveMutation,
    UserSettingSaveMutationVariables
  >(USER_SETTINGS_SAVE, variables)
  return data
}
