import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Box,
} from "@mui/material"
import { Article } from "generated/graphql"
import React from "react"
import { useTranslation } from "react-i18next"

export interface ArticleInfoProps {
  article: Article
}

const ArticleInfo = (props: ArticleInfoProps) => {
  const { article } = props
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
      }}
    >
      <Card
        sx={{ width: "100%", justifyContent: "center", marginRight: "8px" }}
      >
        <Typography sx={{ textAlign: "center" }} variant="body2">
          {t("amount")}
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
          }}
          variant="body1"
        >
          {article.count}
        </Typography>
      </Card>

      <Card
        sx={{ width: "200%", justifyContent: "center", marginRight: "8px" }}
      >
        <Typography sx={{ textAlign: "center" }} variant="body2">
          {t("botanicalName")}
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
          }}
          variant="body1"
        >
          {article.name}
        </Typography>
      </Card>

      <Card sx={{ width: "100%", justifyContent: "center" }}>
        <Typography
          sx={{ textAlign: "center", marginRight: "8px" }}
          variant="body2"
        >
          {t("size")}
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
          }}
          variant="body1"
        >
          {article.size}
        </Typography>
      </Card>
    </Box>
  )
}

//   return (
//     <Card>
//       <CardContent>
//         <Table size="small">
//           <TableHead>
//             <TableRow>
//               <TableCell>{t("amount")}</TableCell>
//               <TableCell>{t("botanicalName")}</TableCell>
//               <TableCell>{t("size")}</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             <TableRow>
//               <TableCell>{article.count}</TableCell>
//               <TableCell>{article.name}</TableCell>
//               <TableCell>{article.size}</TableCell>
//             </TableRow>
//           </TableBody>
//         </Table>
//       </CardContent>
//     </Card>
//   )
// }

export default ArticleInfo
