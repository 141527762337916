import { fetchGQL } from "app/api"
import { TagItemMutation, TagItemMutationVariables } from "generated/graphql"
import gql from "graphql-tag"

const TAG_ITEM = gql`
  mutation tagItem($barcode: String!) {
    tagItem(barcode: $barcode) {
      status
      scannedArticle {
        id
        artNum
        name
        size
        count
        supplier
        tagged
        timeTagged
        personTagged
        distributed
        timeDistributed
        personDistributed
        isSplitted
        isDeleted
        isPalletArticle
        currentPrice
        pmLocation
      }
      orderDetails {
        orderID
        status
        sh
        plantCount
        itemCount
        packageCount
        targetDate
        deliveryAddress {
          id
          firstName
          lastName
          anrede
          company
          fullName
          street
          zipCode
          city
          country
          countryCode
        }
        items {
          id
          artNum
          name
          size
          count
          supplier
          tagged
          timeTagged
          personTagged
          distributed
          timeDistributed
          personDistributed
          isSplitted
          isDeleted
          isPalletArticle
          currentPrice
          pmLocation
        }
      }
    }
  }
`

export const tagItem = async (
  barcode: string
): Promise<TagItemMutation | undefined> => {
  const variables: TagItemMutationVariables = { barcode }
  const data = await fetchGQL<TagItemMutation, TagItemMutationVariables>(
    TAG_ITEM,
    variables
  )
  return data
}
