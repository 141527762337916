import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material"
import CustomInput from "component/CustomInput"
import CustomTabPanel from "component/CustomTabPanel"
import { Order } from "generated/graphql"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { AppRoute } from "utils/routes"
import { getArticleStatus } from "queries/articleStatus"
import StatusStatus from "./StatusStatus"
import CollapsibleRowArticle from "component/CollapsibleRowArticle"
import { articleSplit } from "queries/articleSplit"
import { useSnackbar } from "notistack"
import { isChar } from "utils/isChar"
import { removeFirstChar } from "utils/removeFirstChar"

const StatusPage = (): JSX.Element => {
  const nav = useNavigate()
  const [barcode, setBarcode] = useState<string>("")
  const [value, setValue] = useState(0)
  const [order, setOrder] = useState<Order | null>(null)
  const [newOrder, setNewOrder] = useState<Order | null>(null)
  const [background, setBackground] = useState("")
  const barcodeInputRef = useRef<HTMLInputElement>(null)
  const { t } = useTranslation()
  const [checkedItems, setCheckedItems] = useState<string[]>([])
  const [status, setStatus] = useState<string>("")
  const [openDialog, setOpenDialog] = useState(false)
  const [naliTabDisabled, setNaliTabDisabled] = useState(true)
  const [tabName, setTabName] = useState(t("status"))
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (barcodeInputRef.current) {
      barcodeInputRef.current.focus()
    }
  }, [barcode])

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const handleBarcodeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBarcode(event.target.value)
  }

  const getOrderDetails = async (barcode: string) => {
    if (barcode === "") {
      enqueueSnackbar(t("enterBarcode"), { variant: "warning" })
      return
    }
    //if barcode starts with a char, remove it
    if (isChar(barcode[0])) {
      barcode = removeFirstChar(barcode)
    }

    const res = await getArticleStatus(barcode)
    if (res?.articleStatus) {
      setOrder(res.articleStatus)
      setBackground("success.light")
      setStatus(res.articleStatus.status.toString()) // TODO: Hier noch richtigen Statustext einfügen
      setTabName(t("status"))

      switch (res.articleStatus.status) {
        case 0:
          setBackground("error.light")
          setStatus("BarcodeNotOk")
          break
        default:
          setBackground("success.light")
          setStatus("OK")
      }
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault()
      getOrderDetails(barcode)
      setBarcode("")
    }
  }

  const handleSplittButton = () => {
    if (checkedItems.length == 0) {
      setStatus("Artikel zum Splitten auswählen.")
      setBackground("error.light")
      return
    }
    setOpenDialog(true)
    setBackground("success.light")
    setStatus("OK")
  }

  const handleSplitOk = async () => {
    const numberArray: number[] = checkedItems.map((str) => parseInt(str, 10))
    const res = await articleSplit({ articleIds: [...numberArray] })

    if (res?.articleSplit.newOrderDetails && res.articleSplit.orderDetails) {
      setNewOrder(res.articleSplit.newOrderDetails)
      setOrder(res.articleSplit.orderDetails)
    }
    setNaliTabDisabled(false)
    setOpenDialog(false)
    setTabName(t("oldOrder"))
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        backgroundColor: background,
        flexGrow: 1,
        flex: 1,
        paddingTop: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          alignItems: "flex-start",
          paddingRight: "1%",
          paddingLeft: "1%",
        }}
      >
        <IconButton
          onClick={() => {
            nav(AppRoute.NAVIGATION)
          }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography
          sx={{
            padding: "8px",
          }}
          variant="body1"
        >
          {t("status")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <CustomInput
            sx={{ width: "8rem" }}
            id="barcode"
            placeholder="Barcode"
            data-cy="barcodeInput"
            inputRef={barcodeInputRef}
            value={barcode}
            onChange={handleBarcodeInput}
            onKeyDown={handleKeyDown}
          />
        </Box>
      </Box>

      {order && (
        <Box
          sx={{
            paddingTop: "1%",
            paddingRight: "1%",
            paddingLeft: "1%",
            height: `100vh`,
          }}
        >
          <Card>
            <CardContent>
              <Typography sx={{ textAlign: "center" }} variant="h6">
                {t(`${status}`)}
              </Typography>
            </CardContent>
          </Card>

          {status === "OK" && (
            <>
              <Box
                sx={{
                  paddingTop: 1,
                  display: "flex",
                  flex: 10,
                  flexGrow: 1,
                }}
              >
                <CustomTabPanel value={value} index={0}>
                  {order && (
                    <StatusStatus
                      orderDetails={order}
                      setTagRes={setOrder}
                      height={443}
                      setCheckedItems={setCheckedItems}
                    />
                  )}
                  <Box
                    sx={{
                      paddingBottom: 1,
                      display: "flex",
                      justifyContent: "space-around",
                      flex: 10,
                      flexGrow: 1,
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={handleSplittButton}
                      sx={{ width: "60%" }}
                      color="error"
                    >
                      {t("Split order")}
                    </Button>
                  </Box>
                </CustomTabPanel>

                <CustomTabPanel value={value} index={1}>
                  {newOrder && (
                    <StatusStatus
                      orderDetails={newOrder}
                      setTagRes={setOrder}
                      height={398}
                      setCheckedItems={setCheckedItems}
                    />
                  )}
                </CustomTabPanel>
              </Box>

              <Tabs
                value={value}
                variant="fullWidth"
                centered
                sx={{
                  backgroundColor: "white",
                  zIndex: 1000,
                }}
                onChange={handleChange}
              >
                <Tab label={tabName} data-cy="tabStatus" id="0" />
                <Tab
                  label={t("nali")}
                  data-cy="tabNali"
                  id="1"
                  disabled={naliTabDisabled}
                />
              </Tabs>
            </>
          )}
        </Box>
      )}
      <Dialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false)
        }}
      >
        <DialogTitle>Folgende Artikel splitten?</DialogTitle>
        <DialogContent sx={{ p: 1 }}>
          {order?.items.map((article) => {
            if (checkedItems.includes(article.id.toString()))
              return (
                <Box key={article.id}>
                  <CollapsibleRowArticle article={article} />{" "}
                  <Divider sx={{ mt: 1, mb: 1 }} />
                </Box>
              )
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSplitOk} variant="contained">
            {t("OK")}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setOpenDialog(false)
            }}
          >
            {t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default StatusPage
