import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Typography,
} from "@mui/material"
import CustomInput from "component/CustomInput"
import { t } from "i18next"
import React, { useEffect, useRef, useState } from "react"
import { AppRoute } from "utils/routes"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { useNavigate } from "react-router-dom"
import { articleCheckEanExists } from "queries/articleCheckEanExists"
import { articleUpdateStoragePlace } from "queries/articleUpdateStoragePlace"
import { useSnackbar } from "notistack"

const LagerPage = () => {
  const [background, setBackground] = useState("")
  const nav = useNavigate()
  const [value, setValue] = useState<string>("")
  const [ean2, setEan2] = useState<string>("")
  const [eanExists, setEanExists] = useState(false)
  const eanInputRef = useRef<HTMLInputElement>(null)
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (eanInputRef.current) {
      eanInputRef.current.focus()
    }
  }, [value])

  const handleBarcodeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }

  const checkEanExists = async () => {
    const eanExist = await articleCheckEanExists(value)
    if (eanExist?.articleCheckEanExists) {
      setBackground("success.light")
      setEanExists(true)
    } else {
      setBackground("error.light")
      setEanExists(false)
    }
  }

  const updateStorage = async () => {
    const input = {
      eanBarcode: ean2,
      storagePlace: value,
    }
    const articleStorageUpdated = await articleUpdateStoragePlace(input)
    if (articleStorageUpdated?.articleUpdateStorageplace) {
      enqueueSnackbar(t("addedStoragePlace"), { variant: "success" })
    } else {
      enqueueSnackbar(t("didNotWork"), { variant: "error" })
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault()
      if (eanExists) {
        updateStorage()
        setValue("")
        setEanExists(false)
        setEan2("")
        setBackground("")
      } else {
        checkEanExists()
        setEan2(value)
        setValue("")
      }
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        backgroundColor: background,
        flexGrow: 1,
        flex: 1,
        paddingTop: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          alignItems: "flex-start",
          paddingRight: "1%",
          paddingLeft: "1%",
        }}
      >
        <IconButton
          onClick={() => {
            nav(AppRoute.NAVIGATION)
          }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography
          sx={{
            padding: "8px",
          }}
          variant="body1"
        >
          {t("warehouse")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <CustomInput
            sx={{ width: "8rem" }}
            id="ean"
            placeholder={eanExists ? "Lagerplatz" : "EAN"}
            data-cy="eanInput"
            inputRef={eanInputRef}
            value={value}
            onChange={handleBarcodeInput}
            onKeyDown={handleKeyDown}
          />
        </Box>
      </Box>
      {ean2 && (
        <Box width="75%" height="90vh" alignSelf="center">
          <Card sx={{ p: 3 }}>
            {eanExists && (
              <CardContent>
                <Typography variant="h5" align="center">
                  {t("articleWithEan")} {ean2} {t("found")}!
                </Typography>
                <Divider sx={{ mt: 1, mb: 1 }} />
                <Typography variant="h5" align="center">
                  {t("scanningStorageOrPutInText")}!
                </Typography>
              </CardContent>
            )}
            {!eanExists && (
              <CardContent>
                <Typography variant="h5" align="center">
                  {t("articleWithEan")} {ean2} {t("not")} {t("found")}!
                </Typography>
                <Typography variant="h5" align="center">
                  {t("scanNextArticle")}!
                </Typography>
              </CardContent>
            )}
          </Card>
        </Box>
      )}
    </Box>
  )
}
export default LagerPage
