import { fetchGQL } from "app/api"
import {
  ArticleNewInput,
  ArticleNewMutation,
  ArticleNewMutationVariables,
} from "generated/graphql"
import gql from "graphql-tag"

const ARTICLE_NEW = gql`
  mutation articleNew($input: ArticleNewInput!) {
    articleNew(input: $input)
  }
`

export const articleNew = async (
  input: ArticleNewInput
): Promise<ArticleNewMutation | undefined> => {
  const variables: ArticleNewMutationVariables = { input: { ...input } }
  const data = await fetchGQL<ArticleNewMutation, ArticleNewMutationVariables>(
    ARTICLE_NEW,
    variables
  )
  return data
}
