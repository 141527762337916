import gql from "graphql-tag"
import { fetchGQL } from "app/api"
import {
  ShipLabelCancelMutation,
  ShipLabelCancelMutationVariables,
} from "generated/graphql"

const SHIP_LABEL_CANCEL = gql`
  mutation shipLabelCancel($id: String!) {
    shipLabelCancel(id: $id)
  }
`

export const shipLabelCancel = async (
  variables: ShipLabelCancelMutationVariables
): Promise<ShipLabelCancelMutation | undefined> => {
  const data = await fetchGQL<
    ShipLabelCancelMutation,
    ShipLabelCancelMutationVariables
  >(SHIP_LABEL_CANCEL, variables)

  return data
}
