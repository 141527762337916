import {
  TableRow,
  TableCell,
  Box,
  Table,
  TableBody,
  Card,
  CardContent,
  TableHead,
  Typography,
} from "@mui/material"
import CollapsibleRow from "component/CollapsibleRow"
import { Order } from "generated/graphql"
import React from "react"
import { useTranslation } from "react-i18next"
import QRCode from "react-qr-code"

interface StatusStatusProps {
  orderDetails: Order
  setTagRes: React.Dispatch<React.SetStateAction<Order | null>>
  height: number
  setCheckedItems: (state: any) => void
}

const StatusStatus = (props: StatusStatusProps) => {
  const { orderDetails, setTagRes, height, setCheckedItems } = props

  const { t } = useTranslation()

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        width: "100%",
        pb: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          pb: 1,
          justifyContent: "space-between",
        }}
      >
        <Card sx={{ padding: 1 }}>
          {orderDetails.deliveryAddress && (
            <CardContent>
              <Typography variant="body2">
                {orderDetails.deliveryAddress.company}
              </Typography>
              <Typography variant="body2">
                {orderDetails.deliveryAddress.fullName}
              </Typography>
              <Typography variant="body2">
                {orderDetails.deliveryAddress.street}{" "}
              </Typography>
              <Typography variant="body2">
                {orderDetails.deliveryAddress.zipCode}{" "}
                {orderDetails.deliveryAddress.city}
              </Typography>
              <Typography variant="body2">
                <strong>
                  {orderDetails.deliveryAddress.countryCode}{" "}
                  {orderDetails.deliveryAddress.country}
                </strong>
              </Typography>
            </CardContent>
          )}
        </Card>
        <Card sx={{ padding: "3px", width: "96px", height: "96px" }}>
          <QRCode size={90} value={"value"} viewBox={`0 0 90 90`} />
        </Card>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          mb: "8px",
        }}
      >
        <Card
          sx={{ width: "100%", justifyContent: "center", marginRight: "8px" }}
        >
          <Typography sx={{ textAlign: "center" }} variant="body2">
            {t("orderID")}
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
            }}
            variant="body1"
          >
            {orderDetails.orderID}
          </Typography>
        </Card>

        <Card
          sx={{ width: "100%", justifyContent: "center", marginRight: "8px" }}
        >
          <Typography sx={{ textAlign: "center" }} variant="body2">
            {t("status")}
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
            }}
            variant="body1"
          >
            {orderDetails.status}
          </Typography>
        </Card>

        <Card sx={{ width: "100%", justifyContent: "center" }}>
          <Typography sx={{ textAlign: "center" }} variant="body2">
            {t("SH")}
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
            }}
            variant="body1"
          >
            {orderDetails.sh}
          </Typography>
        </Card>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <Card
          sx={{ width: "100%", justifyContent: "center", marginRight: "8px" }}
        >
          <Typography sx={{ textAlign: "center" }} variant="body2">
            {t("packages")}
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
            }}
            variant="body1"
          >
            {orderDetails.packageCount}
          </Typography>
        </Card>

        <Card
          sx={{ width: "100%", justifyContent: "center", marginRight: "8px" }}
        >
          <Typography sx={{ textAlign: "center" }} variant="body2">
            {t("articles")}
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
            }}
            variant="body1"
          >
            {orderDetails.plantCount}
          </Typography>
        </Card>

        <Card sx={{ width: "100%", justifyContent: "center" }}>
          <Typography
            sx={{ textAlign: "center", marginRight: "8px" }}
            variant="body2"
          >
            {t("totalItemCount")}
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
            }}
            variant="body1"
          >
            {orderDetails.itemCount}
          </Typography>
        </Card>
      </Box>

      <Card
        sx={{
          marginTop: 1,
          height: `calc(100vh - ${height}px)`,
          width: "100%",
          overflow: "auto",
        }}
      >
        <CardContent sx={{ padding: 0 }}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>{t("articles")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderDetails.items.map((value: any) => (
                <CollapsibleRow
                  key={value.id}
                  orderID={orderDetails.orderID}
                  article={value}
                  setTagRes={setTagRes}
                  setCheckedItems={setCheckedItems}
                />
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </Box>
  )
}

export default StatusStatus
