import { fetchGQL } from "app/api"
import { PrintersQuery, PrintersQueryVariables } from "generated/graphql"
import gql from "graphql-tag"

const GET_PRINTERS = gql`
  query printers {
    printers {
      printerID
      printerIP
      printerName
      printerPort
    }
  }
`

export const getPrinters = async (): Promise<PrintersQuery | undefined> => {
  const data = await fetchGQL<PrintersQuery, PrintersQueryVariables>(
    GET_PRINTERS
  )

  return data
}
