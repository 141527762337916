import { fetchGQL } from "app/api"
import {
  OrderItemResetInput,
  OrderItemResetMutation,
  OrderItemResetMutationVariables,
} from "generated/graphql"

import gql from "graphql-tag"

const ORDER_ITEM_RESET = gql`
  mutation orderItemReset($in: OrderItemResetInput!) {
    orderItemReset(in: $in) {
      status
      scannedArticle {
        id
        artNum
        name
        size
        count
        supplier
        tagged
        timeTagged
        personTagged
        distributed
        timeDistributed
        personDistributed
        isSplitted
        isDeleted
        isPalletArticle
        currentPrice
        pmLocation
      }
      orderDetails {
        orderID
        status
        sh
        plantCount
        itemCount
        packageCount
        targetDate
        deliveryAddress {
          id
          company
          fullName
          street
          zipCode
          city
          country
          countryCode
        }
        items {
          id
          artNum
          name
          size
          count
          supplier
          tagged
          timeTagged
          personTagged
          distributed
          timeDistributed
          personDistributed
          isSplitted
          isDeleted
          isPalletArticle
          currentPrice
          pmLocation
        }
      }
    }
  }
`

export const orderItemReset = async (
  input: OrderItemResetInput
): Promise<OrderItemResetMutation | undefined> => {
  const variables: OrderItemResetMutationVariables = { in: input }
  const data = await fetchGQL<
    OrderItemResetMutation,
    OrderItemResetMutationVariables
  >(ORDER_ITEM_RESET, variables)
  return data
}
