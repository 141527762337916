import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Box,
} from "@mui/material"
import { Order } from "generated/graphql"
import React from "react"
import { useTranslation } from "react-i18next"

export interface OrderInfoProps {
  orderDetails: Order
}

const OrderInfo = (props: OrderInfoProps): JSX.Element => {
  const { orderDetails } = props
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
      }}
    >
      <Card
        sx={{ width: "100%", justifyContent: "center", marginRight: "8px" }}
      >
        <Typography sx={{ textAlign: "center" }} variant="body2">
          {t("SH")}
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
          }}
          variant="body1"
        >
          {orderDetails.sh}
        </Typography>
      </Card>

      <Card
        sx={{ width: "100%", justifyContent: "center", marginRight: "8px" }}
      >
        <Typography sx={{ textAlign: "center" }} variant="body2">
          {t("totalItemCount")}
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
          }}
          variant="body1"
        >
          {orderDetails.itemCount}
        </Typography>
      </Card>

      <Card
        sx={{ width: "100%", justifyContent: "center", marginRight: "8px" }}
      >
        <Typography
          sx={{ textAlign: "center", marginRight: "8px" }}
          variant="body2"
        >
          {t("totalDifferentPlantCount")}
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
          }}
          variant="body1"
        >
          {orderDetails.plantCount}
        </Typography>
      </Card>

      <Card sx={{ width: "100%", justifyContent: "center" }}>
        <Typography sx={{ textAlign: "center" }} variant="body2">
          {t("targetDate")}
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
          }}
          variant="body1"
        >
          {orderDetails.targetDate}
        </Typography>
      </Card>
    </Box>
  )
}

export default OrderInfo
