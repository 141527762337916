import gql from "graphql-tag"
import { fetchGQL } from "app/api"
import {
  ShipLabelMutation,
  ShipLabelMutationVariables,
} from "generated/graphql"

const SHIP_LABEL = gql`
  mutation shipLabel($in: ShiplabelInput!) {
    shipLabel(in: $in)
  }
`

export const shipLabel = async (
  variables: ShipLabelMutationVariables
): Promise<ShipLabelMutation | undefined> => {
  const data = await fetchGQL<ShipLabelMutation, ShipLabelMutationVariables>(
    SHIP_LABEL,
    variables
  )

  return data
}
