import {
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import CollapsibleRow from "component/CollapsibleRow"
import { Article, Order, ScanResponse } from "generated/graphql"
import React from "react"
import { useTranslation } from "react-i18next"
import QRCode from "react-qr-code"

interface LabellingStatusProps {
  orderDetails: Order
  setTagRes: React.Dispatch<React.SetStateAction<ScanResponse | null>>
  height: number
  setCheckedItems?: (state: any) => void
}

const LabellingStatus = (props: LabellingStatusProps) => {
  const { orderDetails, setTagRes, height, setCheckedItems = () => {} } = props

  const { t } = useTranslation()

  return (
    <Grid container spacing={1}>
      <Grid item xs={8}>
        <Card sx={{ padding: 1 }}>
          {orderDetails.deliveryAddress && (
            <CardContent>
              <Typography variant="body2">
                {orderDetails.deliveryAddress.company}
              </Typography>
              <Typography variant="body2">
                {orderDetails.deliveryAddress.fullName}
              </Typography>
              <Typography variant="body2">
                {orderDetails.deliveryAddress.street}
              </Typography>
              <Typography variant="body2">
                {orderDetails.deliveryAddress.zipCode}{" "}
                {orderDetails.deliveryAddress.city}
              </Typography>
              <Typography variant="body2">
                <strong>
                  {orderDetails.deliveryAddress.countryCode}{" "}
                  {orderDetails.deliveryAddress.country}
                </strong>
              </Typography>
            </CardContent>
          )}
        </Card>
      </Grid>
      <Grid item xs={4}>
        <Card
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <QRCode size={90} value={"value"} viewBox={`0 0 90 90`} />
        </Card>
      </Grid>

      <Grid item xs={4}>
        <Card sx={{ width: "100%", justifyContent: "center" }}>
          <Typography sx={{ textAlign: "center" }} variant="body2">
            {t("orderID")}
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
            }}
            variant="body1"
          >
            {orderDetails.orderID}
          </Typography>
        </Card>
      </Grid>
      <Grid item xs={4}>
        <Card sx={{ width: "100%", justifyContent: "center" }}>
          <Typography sx={{ textAlign: "center" }} variant="body2">
            {t("status")}
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
            }}
            variant="body1"
          >
            {orderDetails.status}
          </Typography>
        </Card>
      </Grid>

      <Grid item xs={4}>
        <Card sx={{ width: "100%", justifyContent: "center" }}>
          <Typography sx={{ textAlign: "center" }} variant="body2">
            {t("SH")}
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
            }}
            variant="body1"
          >
            {orderDetails.sh}
          </Typography>
        </Card>
      </Grid>

      <Grid item xs={4}>
        <Card sx={{ width: "100%", justifyContent: "center" }}>
          <Typography sx={{ textAlign: "center" }} variant="body2">
            {t("packages")}
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
            }}
            variant="body1"
          >
            {orderDetails.packageCount}
          </Typography>
        </Card>
      </Grid>

      <Grid item xs={4}>
        <Card sx={{ width: "100%", justifyContent: "center" }}>
          <Typography sx={{ textAlign: "center" }} variant="body2">
            {t("articles")}
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
            }}
            variant="body1"
          >
            {orderDetails.plantCount}
          </Typography>
        </Card>
      </Grid>

      <Grid item xs={4}>
        <Card sx={{ width: "100%", justifyContent: "center" }}>
          <Typography sx={{ textAlign: "center" }} variant="body2">
            {t("totalItemCount")}
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
            }}
            variant="body1"
          >
            {orderDetails.itemCount}
          </Typography>
        </Card>
      </Grid>

      <Grid item xs={12} sx={{ marginBottom: 1 }}>
        <Card
          sx={{
            marginTop: 1,
            height: `calc(100vh - ${height}px)`,
            width: "100%",
            overflow: "scroll",
          }}
        >
          <CardContent sx={{ padding: 0 }}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>{t("articles")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderDetails.items.map((article: Article) => (
                  <CollapsibleRow
                    key={article.id}
                    orderID={orderDetails.orderID}
                    article={article}
                    setTagRes={setTagRes}
                    setCheckedItems={setCheckedItems}
                  />
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default LabellingStatus
