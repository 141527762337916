import { fetchGQL } from "app/api"
import { LibraListQuery, LibraListQueryVariables } from "generated/graphql"
import gql from "graphql-tag"

const GET_LIBRALIST = gql`
  query libraList {
    libraList {
      id
      ip
      port
    }
  }
`

export const getlibraList = async (): Promise<LibraListQuery | undefined> => {
  const data = await fetchGQL<LibraListQuery, LibraListQueryVariables>(
    GET_LIBRALIST
  )

  return data
}
