import { fetchGQL } from "app/api"
import {
  ArticleStatusQuery,
  ArticleStatusQueryVariables,
} from "generated/graphql"
import gql from "graphql-tag"

const ARTICLE_STATUS = gql`
  query articleStatus($barcode: String!) {
    articleStatus(barcode: $barcode) {
      orderID
      status
      sh
      plantCount
      itemCount
      targetDate
      packageCount
      deliveryAddress {
        id
        company
        anrede
        firstName
        lastName
        fullName
        street
        zipCode
        city
        country
        countryCode
      }
      items {
        id
        artNum
        name
        size
        count
        supplier
        tagged
        timeTagged
        personTagged
        distributed
        timeDistributed
        personDistributed
        isSplitted
        isDeleted
        isPalletArticle
        currentPrice
        pmLocation
      }
    }
  }
`

export const getArticleStatus = async (
  barcode: string
): Promise<ArticleStatusQuery | undefined> => {
  const variables: ArticleStatusQueryVariables = { barcode }
  const data = await fetchGQL<ArticleStatusQuery, ArticleStatusQueryVariables>(
    ARTICLE_STATUS,
    variables
  )
  return data
}
