import { Dialog, DialogContent, DialogTitle } from "@mui/material"
import React, { useEffect, useRef } from "react"
import CustomInput from "./CustomInput"

export interface CustomDialogProps {
  open: boolean
  setOpen: (open: boolean) => void
  val: string
  onChange: (newValue: string) => void
  headerText: string
  icon: JSX.Element
  label?: string
  onSubmit: () => void
}

const CustomDialog = (props: CustomDialogProps): JSX.Element => {
  const { val, onChange, headerText, icon, open, setOpen, label, onSubmit } =
    props
  const ref = useRef<HTMLInputElement>(null)

  const changeHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    onChange(event.currentTarget.value)
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    ref.current?.focus()
    if (event.key === "Enter") {
      event.preventDefault()
      onSubmit()
    }
  }
  // add event listener to the document and focus the input
  // when the dialog is opened
  const focusElem = (_: KeyboardEvent) => {
    ref.current?.focus()
  }

  useEffect(() => {
    if (open) {
      document.addEventListener("keydown", focusElem)
    }
    return () => {
      document.removeEventListener("keydown", focusElem)
    }
  }, [open])

  return (
    <Dialog open={open} fullWidth maxWidth="xl" onClose={() => setOpen(false)}>
      <>
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {icon}
          {headerText}
        </DialogTitle>
        <DialogContent>
          <CustomInput
            id="name"
            fullWidth
            inputRef={ref}
            value={val}
            placeholder={label}
            onChange={changeHandler}
            onKeyDown={handleKeyDown}
          />
        </DialogContent>
      </>
    </Dialog>
  )
}

export default CustomDialog
