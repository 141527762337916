export const AppRoute = {
  LOGIN: "/",
  NAVIGATION: "/navigation",

  // Navigation
  LABEL: "/label",
  DIST: "/dist",
  // SHELF: "/shelf",
  STATUS: "/status",
  SHIP: "/ship",
  PLANT: "/plant",
  SCAN: "/scan",
  USER: "/user",
  PRINTER_LIBRA_SETTINGS: "/printer-libra-settings",
  WAREHOUSE: "/warehouse",
  TREASURE: "/treasure",

  //WAREHOUSE: "",
  SHELF: "",
  //STATUS: "",
  RESET: "",
  //TREASURE: "",
  //PLANT: "",
  //SCAN: "",
  //USER: "",
}
