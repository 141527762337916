import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material"
import { Article, Order } from "generated/graphql"
import React from "react"
import { useTranslation } from "react-i18next"

import CollapsibleRow from "./CollapsibleRow"

export interface ArticleListProps {
  articlesDetails: Order
  maxHeight?: number
  setTagRes: React.Dispatch<React.SetStateAction<Order | null>>
  setCheckedItems: (state: any) => void
}

const ArticleListShipment = (props: ArticleListProps): JSX.Element => {
  const { articlesDetails, maxHeight, setTagRes, setCheckedItems } = props

  const articles = articlesDetails.items
  var height = 270
  if (maxHeight) {
    height = maxHeight
  }
  const { t } = useTranslation()

  return (
    <Card
      sx={{
        height: `calc(100vh - ${height}px)`,
        width: "100%",
        overflow: "scroll",
      }}
    >
      <CardContent>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell colSpan={3}>{t("restArticles")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {articles.map((value: Article) => (
              <CollapsibleRow
                key={value.id}
                orderID={articlesDetails.orderID}
                article={value}
                setTagRes={setTagRes}
                setCheckedItems={setCheckedItems}
              />
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}

export default ArticleListShipment
