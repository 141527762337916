import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material"
import Lottie from "lottie-react"
import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import scan from "../animations/scan.json"

interface SecurityScanDialogProps {
  open: boolean
  openPrintDialog: () => void
  onAuthkeyConfirm: (key: string) => void
  openSettingDialog: () => void
  isSettingOpen: boolean
}

const SecurityScanDialog = (props: SecurityScanDialogProps) => {
  const { open, openPrintDialog, openSettingDialog, onAuthkeyConfirm } = props
  const [val, setVal] = useState("")
  const { t } = useTranslation()
  const ref = useRef<HTMLInputElement>(null)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVal(event.target.value)
  }

  const handleConfirm = (e: React.KeyboardEvent) => {
    if (val !== "" && e.key === "Enter") {
      onAuthkeyConfirm(val)
    }
  }

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        {t("securityCodeScan")}
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Lottie animationData={scan} style={{ height: 150 }} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="name"
              fullWidth
              data-cy="securityScanDialogInput"
              value={val}
              onChange={handleChange}
              onKeyDown={handleConfirm}
              inputRef={ref}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <Button
        variant="contained"
        onClick={openPrintDialog}
        sx={{ ml: 3, mr: 3, mb: 3 }}
      >
        {t("securityCodePrint")}
      </Button>
      <Button
        variant="contained"
        onClick={openSettingDialog}
        sx={{ ml: 3, mr: 3, mb: 3 }}
      >
        {t("settings")}
      </Button>
    </Dialog>
  )
}

export default SecurityScanDialog
