import { fetchGQL } from "app/api"
import {
  ArticleCheckEanExistsQuery,
  ArticleCheckEanExistsQueryVariables,
} from "generated/graphql"
import gql from "graphql-tag"

const ARTICLE_CHECK_EAN_EXISTS = gql`
  query articleCheckEanExists($ean: String!) {
    articleCheckEanExists(ean: $ean)
  }
`

export const articleCheckEanExists = async (
  ean: string
): Promise<ArticleCheckEanExistsQuery | undefined> => {
  const variables: ArticleCheckEanExistsQueryVariables = { ean }
  const data = await fetchGQL<
    ArticleCheckEanExistsQuery,
    ArticleCheckEanExistsQueryVariables
  >(ARTICLE_CHECK_EAN_EXISTS, variables)
  return data
}
