import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import { useAppSelector } from "app/hooks"
import { selectUser } from "app/userSlice"
import ScanHistory from "component/ScanHistory"
import { UserHistory } from "generated/graphql"
import { userAchievement } from "queries/userArchievement"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

const LabellingScanHistory = () => {
  const { t } = useTranslation()
  var height = 170

  return (
    <Box sx={{ pb: 1 }}>
      <Card
        sx={{
          height: `calc(100vh - ${height}px)`,
          width: "100%",
          overflow: "auto",
          p: 1,
        }}
      >
        <ScanHistory />
      </Card>
    </Box>
  )
}

export default LabellingScanHistory
