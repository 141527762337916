import { fetchGQL } from "app/api"
import {
  OrderItemDistributeInput,
  OrderItemDistributeMutation,
  OrderItemDistributeMutationVariables,
} from "generated/graphql"

import gql from "graphql-tag"

const ORDER_ITEM_DISTRIBUTE = gql`
  mutation orderItemDistribute($in: OrderItemDistributeInput!) {
    orderItemDistribute(in: $in) {
      status
      scannedArticle {
        id
        artNum
        name
        size
        count
        supplier
        tagged
        timeTagged
        personTagged
        distributed
        timeDistributed
        personDistributed
        isSplitted
        isDeleted
        isPalletArticle
        currentPrice
        pmLocation
      }
      orderDetails {
        orderID
        status
        sh
        plantCount
        itemCount
        packageCount
        targetDate
        deliveryAddress {
          id
          firstName
          lastName
          anrede
          company
          fullName
          street
          zipCode
          city
          country
          countryCode
        }
        items {
          id
          artNum
          name
          size
          count
          supplier
          tagged
          timeTagged
          personTagged
          distributed
          timeDistributed
          personDistributed
          isSplitted
          isDeleted
          isPalletArticle
          currentPrice
          pmLocation
        }
      }
    }
  }
`

export const orderItemDistribute = async (
  input: OrderItemDistributeInput
): Promise<OrderItemDistributeMutation | undefined> => {
  const variables: OrderItemDistributeMutationVariables = { in: input }
  const data = await fetchGQL<
    OrderItemDistributeMutation,
    OrderItemDistributeMutationVariables
  >(ORDER_ITEM_DISTRIBUTE, variables)
  return data
}
