import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew"
import { Box, Grid, IconButton, Typography, useTheme } from "@mui/material"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { logout, selectUser } from "app/userSlice"
import { UserHistory } from "generated/graphql"
import { fetchUserAchievement } from "queries/fetchUserAchievemnt"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import i18n from "translation/translation"
import StatusHeaderBar from "./StatusHeaderBar"

const NavigationHeader = () => {
  const userData = useAppSelector(selectUser)
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const [achievementsData, setAchievementsData] = useState<UserHistory>({
    itemsDistributed: 0,
    itemsLabled: 0,
    packagesDispatched: 0,
    articleHistory: [],
  })
  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    if (userData.user.id !== 0 && i18n.language !== userData.user.language) {
      i18n.changeLanguage(userData.user.language)
    }
    // lazy load userHistory
    if (userData.user.id !== 0) {
      fetchUserAchievement(userData.user.id).then((res) => {
        if (res?.userAchievment) {
          setAchievementsData(res.userAchievment)
        }
      })
    }
  }, [userData])

  const handleLogout = () => {
    dispatch(logout())
    navigate("/")
    i18n.changeLanguage("de")
  }

  return (
    <Grid
      container
      spacing={2}
      sx={{ backgroundColor: theme.palette.primary.main, padding: "2%" }}
      direction="row"
    >
      <Grid item xs={6}>
        <Typography variant="h6" style={{ flex: 1, textAlign: "left" }}>
          {t("hello")}, {userData.user.username.toUpperCase()}!
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton
            data-cy="LogoutButton"
            color="secondary"
            onClick={handleLogout}
            sx={{ backgroundColor: theme.palette.error.main }}
          >
            <PowerSettingsNewIcon style={{ color: "white" }} />
          </IconButton>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <StatusHeaderBar
          itemsLabeled={achievementsData.itemsLabled}
          itemsDistributed={achievementsData.itemsDistributed}
          packagesDispatched={achievementsData.packagesDispatched}
        />
      </Grid>
    </Grid>
  )
}

export default NavigationHeader
