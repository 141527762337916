import { fetchGQL } from "app/api"
import {
  ArticleStoragePlaceInput,
  ArticleUpdateStoragePlaceMutation,
  ArticleUpdateStoragePlaceMutationVariables,
} from "generated/graphql"
import gql from "graphql-tag"

const ARTICLE_SPLIT = gql`
  mutation articleUpdateStoragePlace($input: ArticleStoragePlaceInput!) {
    articleUpdateStorageplace(input: $input)
  }
`

export const articleUpdateStoragePlace = async (
  input: ArticleStoragePlaceInput
): Promise<ArticleUpdateStoragePlaceMutation | undefined> => {
  const variables: ArticleUpdateStoragePlaceMutationVariables = {
    input: { ...input },
  }
  const data = await fetchGQL<
    ArticleUpdateStoragePlaceMutation,
    ArticleUpdateStoragePlaceMutationVariables
  >(ARTICLE_SPLIT, variables)
  return data
}
