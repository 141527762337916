import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { selectLibras } from "app/libraListSlice"
import { selectPrinters } from "app/printerSlice"
import {
  selectLibraID,
  selectPrinterID,
  setLibraID,
  setPrinterID,
} from "app/settingSlice"

import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

const LibraPrinterSettings = () => {
  const librasData = useAppSelector(selectLibras)
  const printerData = useAppSelector(selectPrinters)
  const printerId = useAppSelector(selectPrinterID)
  const libraId = useAppSelector(selectLibraID)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const nav = useNavigate()

  const handleSave = () => {
    nav("/navigation")
  }

  const handleChangePrinter = (event: SelectChangeEvent) => {
    dispatch(setPrinterID(event.target.value))
  }

  const handleChangeLibra = (event: SelectChangeEvent) => {
    dispatch(setLibraID(event.target.value))
  }

  return (
    <Dialog open={true} fullWidth onClose={() => nav("/navigation")}>
      <DialogTitle sx={{ background: "#444444", color: "white" }}>
        {t("libraPrinterSettings")}
      </DialogTitle>
      <DialogContent sx={{ mt: 2 }}>
        <InputLabel id="printer">{t("printer")}</InputLabel>
        <Select
          labelId="printer"
          id="printer"
          value={printerId}
          onChange={handleChangePrinter}
          fullWidth
        >
          {printerData.map((printer) => {
            return (
              <MenuItem
                key={printer.printerID}
                value={printer.printerID.toString()}
              >
                <Grid container>
                  <Grid item xs={1}>
                    {printer.printerID}
                  </Grid>
                  <Grid item xs={11}>
                    {printer.printerIP}:{printer.printerPort}
                  </Grid>
                </Grid>
              </MenuItem>
            )
          })}
        </Select>

        <InputLabel id="libra" sx={{ mt: 1 }}>
          {t("libra")}
        </InputLabel>
        <Select
          labelId="libra"
          id="libra"
          value={libraId}
          onChange={handleChangeLibra}
          fullWidth
        >
          {librasData.map((libra) => {
            return (
              <MenuItem key={libra.id} value={libra.id.toString()}>
                <Grid container>
                  <Grid item xs={2}>
                    {libra.id}
                  </Grid>
                  <Grid item xs={10}>
                    {libra.ip}:{libra.port}
                  </Grid>
                </Grid>
              </MenuItem>
            )
          })}
        </Select>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <Button
            variant="contained"
            onClick={handleSave}
            fullWidth
            sx={{ ml: 3, mr: 3, mb: 3 }}
          >
            {t("back")}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default LibraPrinterSettings
