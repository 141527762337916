import { Box } from "@mui/material"
import React from "react"

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const CustomTabPanel = (props: TabPanelProps): JSX.Element => {
  const { children, value, index, ...other } = props

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      {...other}
      sx={{ width: "100%" }}
    >
      {value === index && <>{children}</>}
    </Box>
  )
}

export default CustomTabPanel
