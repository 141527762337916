import {
  Backdrop,
  Box,
  Card,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import { Article } from "generated/graphql"
import React from "react"
import { useTranslation } from "react-i18next"

import CancelIcon from "@mui/icons-material/Cancel"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import ContentCutIcon from "@mui/icons-material/ContentCut"

export interface ArticleListProps {
  articles: Article[]
  displayTagged?: boolean
  displayDistribution?: boolean
  maxHeight?: number
}

const ArticleList = (props: ArticleListProps): JSX.Element => {
  const { articles, displayTagged, displayDistribution, maxHeight } = props
  var height = 270
  if (maxHeight) {
    height = maxHeight
  }
  const { t } = useTranslation()

  const allTaggedAndDistributed = articles.every(
    (article) => article.tagged && article.distributed
  )

  return (
    <Card
      sx={{
        height: `calc(100vh - ${height}px)`,
        width: "100%",
        overflow: "scroll",
      }}
    >
      <CardContent>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell colSpan={3}>{t("restArticles")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {articles.map((value: Article, index) => (
              <TableRow key={index}>
                <TableCell sx={{ textAlign: "left" }}>
                  <Typography variant="body2">{value.name}</Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: allTaggedAndDistributed
                        ? "success.main"
                        : "text.primary",
                    }}
                  >
                    {value.count + " " + t("piece")}
                  </Typography>
                </TableCell>
                <TableCell style={{ position: "relative" }}>
                  {value.isSplitted && (
                    <Typography
                      variant="overline"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ContentCutIcon /> Splitted!
                    </Typography>
                  )}
                </TableCell>

                <TableCell sx={{ maxWidth: "200px" }}>
                  <Typography variant="body2" sx={{ textAlign: "right" }}>
                    {value.size}
                  </Typography>
                  {displayTagged && displayDistribution && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        fontSize: "0.8rem",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {value.tagged ? (
                          <CheckCircleIcon color="success" fontSize="small" />
                        ) : (
                          <CancelIcon color="error" fontSize="small" />
                        )}
                        <Typography variant="body2">{t("labelled")}</Typography>
                      </Box>
                      <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {value.distributed ? (
                          <CheckCircleIcon color="success" fontSize="small" />
                        ) : (
                          <CancelIcon color="error" fontSize="small" />
                        )}
                        <Typography variant="body2">
                          {t("distributed")}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}

export default ArticleList
