import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Libra, LibraListQuery } from "generated/graphql"
import { getlibraList } from "queries/libraList"
import { RootState } from "./store"

export interface libraListate {
  libras: Libra[]
  status: "idle" | "loading" | "failed"
}

const initialState: libraListate = {
  libras: [],
  status: "idle",
}

export const fetchlibraList = createAsyncThunk(
  "libras/fetchlibraList",
  async () => {
    const response = await getlibraList()
    return response
  }
)

export const libraListlice = createSlice({
  name: "libra",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchlibraList.pending, (state) => {
        state.status = "loading"
      })
      .addCase(
        fetchlibraList.fulfilled,
        (state, action: PayloadAction<LibraListQuery | undefined>) => {
          state.status = "idle"
          if (action.payload?.libraList !== undefined) {
            state.libras = action.payload.libraList
          } else if (action.payload?.libraList === undefined) {
            state.libras = []
            state.status = "failed"
          }
        }
      )
      .addCase(fetchlibraList.rejected, (state) => {
        state.status = "failed"
      })
  },
})

export const selectLibras = (state: RootState) => state.libraState.libras

export default libraListlice.reducer
