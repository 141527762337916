import { fetchGQL } from "app/api"
import {
  ArticlePositionInput,
  ArticlePositionMutation,
  ArticlePositionMutationVariables,
} from "generated/graphql"
import gql from "graphql-tag"

const ARTICLE_NEW_POSITION = gql`
  mutation articlePosition($input: ArticlePositionInput!) {
    articlePosition(input: $input)
  }
`

export const articleNewPosition = async (
  input: ArticlePositionInput
): Promise<ArticlePositionMutation | undefined> => {
  const variables: ArticlePositionMutationVariables = { input: { ...input } }
  const data = await fetchGQL<
    ArticlePositionMutation,
    ArticlePositionMutationVariables
  >(ARTICLE_NEW_POSITION, variables)
  return data
}
