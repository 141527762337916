export enum Speech {
  Stop = "Heikel, Heikel, Stop",
  BoxDone = "Kiste, fertig! Kiste, fertig!, Kiste, fertig!",
  Ok = "Ok, Ok, Ok",
}

export const speech = (speech: string) => {
  const synth = window.speechSynthesis
  const utterance = new SpeechSynthesisUtterance(speech)
  utterance.rate = 0.9
  utterance.lang = "de-DE"
  synth.speak(utterance)
}
