import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material"
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt"
import ApartmentIcon from "@mui/icons-material/Apartment"
import SaveIcon from "@mui/icons-material/Save"
import CustomInput from "component/CustomInput"
import { t } from "i18next"
import React, { useEffect, useRef, useState } from "react"
import { AppRoute } from "utils/routes"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { useNavigate } from "react-router-dom"
import { isChar } from "utils/isChar"
import { removeFirstChar } from "utils/removeFirstChar"
import { useSnackbar } from "notistack"
import { getArticleStatus } from "queries/articleStatus"
import { Article } from "generated/graphql"
import ArticleInfo from "component/ArticleInfo"
import { articleNewPosition } from "queries/articleNewPosition"

const iconCss: React.CSSProperties = {
  color: "success.light",
}

const suppliers = [
  { label: "Breckwoldt" },
  { label: "Clasen+Co" },
  { label: "KoJu" },
  { label: "Ackerglück" },
]

const PlantPositionPage = () => {
  const nav = useNavigate()
  const barcodeInputRef = useRef<HTMLInputElement>(null)
  const [barcode, setBarcode] = useState<string>("")
  const [background, setBackground] = useState<string>("error.light")
  const { enqueueSnackbar } = useSnackbar()
  const [status, setStatus] = useState<string>("")
  const [article, setArticle] = useState<Article | null>(null)
  const [pmLocation, setPmLocation] = useState<string>("")
  const [supplier, setSupplier] = useState<string>("")

  useEffect(() => {
    if (barcodeInputRef.current) {
      barcodeInputRef.current.focus()
    }
  }, [barcode])

  const getOrderDetails = async (barcode: string) => {
    if (barcode === "") {
      enqueueSnackbar(t("enterBarcode"), { variant: "warning" })
      return
    }
    //if barcode starts with a char, remove it
    if (isChar(barcode[0])) {
      barcode = removeFirstChar(barcode)
    }
    const res = await getArticleStatus(barcode)
    if (res?.articleStatus) {
      const scannedArticle = res.articleStatus.items.filter(
        (items) => items.id.toString() == barcode.slice(0, -2)
      )

      setBackground("success.light")
      setStatus(res.articleStatus.status.toString()) // TODO: Hier noch richtigen Statustext einfügen

      switch (res.articleStatus.status) {
        case 0:
          setBackground("error.light")
          setStatus("BarcodeNotOk")
          break
        default:
          setBackground("success.light")
          setStatus("OK")
          setArticle(scannedArticle[0])
          setPmLocation(scannedArticle[0].pmLocation)
      }
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault()
      getOrderDetails(barcode)
      setBarcode("")
    }
  }

  const handleBarcodeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBarcode(event.target.value)
  }

  const handlePmLocationInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPmLocation(event.target.value)
  }

  const handleSupplierInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSupplier(event.target.value)
  }

  const handleSaveClick = async () => {
    if (article != null) {
      const input = {
        barcode: article.id.toString(),
        position: pmLocation,
        supplier: supplier,
      }
      const data = await articleNewPosition(input)
      setPmLocation("")
      setSupplier("")
      setArticle(null)
      if (data) {
        switch (data.articlePosition) {
          case 1:
            setStatus("OK")
            break
          case 208:
            setStatus("SupplierNoOwnLocation")
            setBackground("error.light")
            break
        }
      }
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        backgroundColor: background,
        flexGrow: 1,
        flex: 1,
        paddingTop: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          alignItems: "flex-start",
          paddingRight: "1%",
          paddingLeft: "1%",
        }}
      >
        <IconButton
          onClick={() => {
            nav(AppRoute.NAVIGATION)
          }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography
          sx={{
            padding: "8px",
          }}
          variant="body1"
        >
          {t("plantPosition")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <CustomInput
            sx={{ width: "8rem" }}
            id="barcode"
            placeholder="Barcode"
            data-cy="barcodeInput"
            inputRef={barcodeInputRef}
            value={barcode}
            onChange={handleBarcodeInput}
            onKeyDown={handleKeyDown}
          />
        </Box>
      </Box>

      <Box
        sx={{
          paddingTop: "1%",
          paddingRight: "1%",
          paddingLeft: "1%",
          height: `calc(100vh - 50px)`,
          width: "100%",
        }}
      >
        <Card sx={{ flex: 1, mb: 1 }}>
          <CardContent>
            <Typography sx={{ textAlign: "center" }} variant="h6">
              {t(`${status}`)}
            </Typography>
          </CardContent>
        </Card>
        <Box sx={{ flex: 1, pb: 1 }}>
          {article && <ArticleInfo article={article} />}
        </Box>

        {status == "OK" && (
          <Card
            sx={{
              overflow: "auto",
              padding: 1,
              paddingBottom: 2,
            }}
          >
            <Box>
              <TextField
                id="source"
                select
                label={t("source")}
                fullWidth
                value={supplier}
                onChange={handleSupplierInput}
                sx={{ mt: 2 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ApartmentIcon sx={iconCss} />
                    </InputAdornment>
                  ),
                }}
              >
                {suppliers.map((option) => (
                  <MenuItem key={option.label} value={option.label}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                sx={{ mt: 2 }}
                label={t("position")}
                id="position"
                value={pmLocation}
                onChange={handlePmLocationInput}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EditLocationAltIcon sx={iconCss} />
                    </InputAdornment>
                  ),
                }}
              ></TextField>
              <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                <Button
                  variant="contained"
                  startIcon={<SaveIcon />}
                  sx={{ mt: 2 }}
                  onClick={handleSaveClick}
                >
                  {t("save")}
                </Button>
              </Box>
            </Box>
          </Card>
        )}
      </Box>
    </Box>
  )
}
export default PlantPositionPage
